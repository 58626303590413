import React, { useCallback, useRef } from 'react';
import {
  DidomiButton,
  DidomiCheckbox,
  DidomiCheckboxField,
  DidomiCollapsibleSection,
  DidomiHintbox,
  DidomiLink,
  DidomiNumberInputField,
  DidomiPasswordInputField,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiSkeleton,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { useFeatureFlag, useSPARouter } from '@didomi/utility-react';
import { Form, Formik, FormikProps } from 'formik';

import { ScenariosSection } from '@components';
import { NO_SCHEDULE_SET, SCHEDULE_LIST_MAP, NOT_NULL_DEFAULT_PASSWORD } from '@constants';
import { useScenarioChanges } from '@hooks';
import { LoadingModal } from '@modals';
import { COUNTRY_LIST, omitScenariosGeneratedFields, PROPERTY_SCHEMA, PropertySchema } from '@utils';
import { Property } from '../../types/responses/property';

export type InitialValues = Partial<Property> & {
  isOwner: boolean;
  filterValue: string;
  isFilterRegex: boolean;
};

interface PropertyFormProps {
  initialValues: InitialValues;
  onSubmit: (property: Partial<Property>) => Promise<void>;
  isLoading?: boolean;
  isSaving?: boolean;
}

const sanitizeLogin = (login: Property['login'] | undefined) => {
  return login?.enabled
    ? JSON.parse(JSON.stringify(login, (key, value) => (value === '' ? undefined : value)))
    : {
        enabled: false,
      };
};

export const PropertyForm = ({ initialValues, onSubmit, isLoading = false, isSaving = false }: PropertyFormProps) => {
  const propertyFormRef = useRef<FormikProps<InitialValues>>();

  const [hasComplianceReportPremiumFeature] = useFeatureFlag('compliance_report_v2');

  const {
    handleEnabledChange,
    handleAddScenario,
    handleDeleteScenario,
    handleNameChange,
    handleAddAction,
    handleDeleteAction,
    handleMoveAction,
    handleCssSelectorChange,
    handleInputValueChange,
  } = useScenarioChanges(propertyFormRef);

  const { navigateTo } = useSPARouter();

  const handleSubmit = useCallback(
    async data => {
      // format the login object
      // to not send empty fields to the API

      // We generate a random id for each action, to give a unique key in the React list.
      // This key allows the action to be rerendered on change (add, update, delete, reorder, etc..).
      // We don't want to send this id to the API, so we remove it before sending the data.
      // Additionally, on edit, when we receive the scenarios and actions,
      // they are populated with id, created_at, and modified_at.
      // We need to remove these fields as well because the DTOs do not expect them.
      const scenariosWithoutActionIDs = omitScenariosGeneratedFields(data.scenarios);
      const filters = [];
      if (data.filterValue.length) {
        filters.push({
          type: data.isFilterRegex ? 'regexp' : 'string',
          value: data.filterValue,
        });
      }

      const property: PropertySchema = PROPERTY_SCHEMA.cast({
        name: data.name,
        website: data.website,
        scenarios: scenariosWithoutActionIDs,
        pages_count: data?.pages_count || 10,
        schedule: data.schedule === NO_SCHEDULE_SET ? null : data.schedule || '0 0 1 * *',
        user_agent: data.user_agent,
        country: data.country,
        login: data?.login,
        filters,
      });
      property.login = sanitizeLogin(property.login);

      await onSubmit(property);
    },
    [onSubmit],
  );

  const handlePasswordFocusIn = () => {
    // if username is non-null, clear the password field on focus
    if (initialValues.id && propertyFormRef.current.values?.login?.enabled) {
      propertyFormRef.current.setFieldValue('login.credentials.password', '', false);
    }
  };

  const handlePasswordFocusOut = () => {
    // if login is enabled and the password is not updated, set the password field to the default value on focus out
    if (initialValues.id && initialValues.login?.enabled && !propertyFormRef.current.values.login?.credentials?.password) {
      propertyFormRef.current.setFieldValue('login.credentials.password', NOT_NULL_DEFAULT_PASSWORD, false);
    }
  };

  return (
    <section className="mt-m">
      <LoadingModal isOpen={isSaving} isEditing={!!initialValues.id} />
      <Formik enableReinitialize initialValues={initialValues} validationSchema={PROPERTY_SCHEMA} onSubmit={handleSubmit} validateOnMount={true} innerRef={propertyFormRef}>
        {({ values, submitForm, setFieldValue }) => (
          <DidomiSkeleton className="w-full" isLoading={isLoading} variant="layout">
            <Form className="w-full max-w-[700px] flex flex-col mb-s">
              <DidomiTextInputField name="name" label="Give this domain a recognisable name *" placeholder="Enter a name..." data-skeleton />
              <DidomiTextInputField name="website" label="Target domain *" placeholder="https://www.myDomain.com" data-skeleton />
              <DidomiSelectField className="max-w-[316px]" name="country" placeholder="" label="Launched from" required={true} data-skeleton>
                <DidomiSelectOptions>
                  {COUNTRY_LIST.map(country => (
                    <DidomiSelectOption key={'country' + country.value} value={country.value} label={country.name} disabled={country.value === values.country} />
                  ))}
                </DidomiSelectOptions>
              </DidomiSelectField>

              <ScenariosSection
                scenarios={propertyFormRef.current?.values.scenarios}
                hasComplianceReportPremiumFeature={hasComplianceReportPremiumFeature}
                onEnabledChange={handleEnabledChange}
                onAddScenario={handleAddScenario}
                onDeleteScenario={handleDeleteScenario}
                onNameChange={handleNameChange}
                onAddAction={handleAddAction}
                onDeleteAction={handleDeleteAction}
                onMoveAction={handleMoveAction}
                onCssSelectorChange={handleCssSelectorChange}
                onInputValueChange={handleInputValueChange}
              />

              <DidomiHintbox titleText="Advanced Settings" variant="neutral" className="mt-xxs mb-s">
                <p>
                  Read our <DidomiLink href="https://support.didomi.io/run-your-audit-add-your-website" text="Help Center"></DidomiLink> for a deep explanation of the advanced
                  settings.
                </p>
              </DidomiHintbox>

              <DidomiCollapsibleSection label="Advanced" data-testid="collapse-settings" className="mb-xs">
                <div>
                  {hasComplianceReportPremiumFeature && (
                    <>
                      <DidomiSelectField
                        className="max-w-[316px]"
                        data-testid="scan-frequency"
                        name="schedule"
                        placeholder="Monthly"
                        label="Frequency of the programmed report"
                        data-skeleton
                      >
                        <DidomiSelectOptions>
                          {Object.entries(SCHEDULE_LIST_MAP).map(([label, value]) => (
                            <DidomiSelectOption key={'schedule' + value} value={value} label={label} />
                          ))}
                        </DidomiSelectOptions>
                      </DidomiSelectField>

                      {values?.schedule === SCHEDULE_LIST_MAP['Daily'] && (
                        <DidomiHintbox iconName="warning" variant="warning" className="mb-xs" data-testid="daily-scan-warning">
                          <p>
                            <span>Enabling daily scans may result in changes to your Didomi payment plan.</span>
                            <br />
                            <span>
                              Please <b>contact Customer Support</b> for more information.
                            </span>
                          </p>
                        </DidomiHintbox>
                      )}
                    </>
                  )}

                  <DidomiTextInputField name="filterValue" label="URL filter, to target specific pages" placeholder="eg. /products/ " data-skeleton />
                  <DidomiCheckbox
                    label="The URL filter is a RegEx"
                    name="isFilterRegex"
                    checked={values.isFilterRegex}
                    onValueChange={event => setFieldValue('isFilterRegex', event.detail)}
                    data-skeleton
                    data-testid="is-filter-regex"
                  />
                  <DidomiNumberInputField name="pages_count" label="N. of pages (20 max)" data-skeleton />

                  {hasComplianceReportPremiumFeature && (
                    <DidomiTextInputField
                      name="user_agent"
                      label="User agent"
                      placeholder="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36 Didomi/1.0"
                      data-skeleton
                    />
                  )}
                </div>
              </DidomiCollapsibleSection>

              {hasComplianceReportPremiumFeature && (
                <DidomiCollapsibleSection label="Scan logged in environment" isCollapsed={!values.login.enabled} className="mb-xs">
                  <div>
                    <p className="text-[12px] text-primary-blue-6 font-semibold mb-xxs">Logged environment configuration</p>

                    <DidomiCheckboxField
                      label="Scan logged environment"
                      checked={values.login.enabled}
                      onValueChange={(event: CustomEvent<boolean>) => setFieldValue('login.enabled', event.detail)}
                      data-skeleton
                      data-testid="auth-enabled-checkbox"
                      name="login.enabled"
                    />

                    {values.login.enabled && (
                      <>
                        <DidomiHintbox titleText="Scan logged in environment" variant="neutral" className="mt-xxs mb-xs">
                          <p>In order to let our bot scan your website while logged-in, we need you to provide the necessary credentials.</p>
                        </DidomiHintbox>

                        <DidomiTextInputField name="login.selectors.pathToForm" label="Login page URL" placeholder="e.g. https://example.com/login/" required data-skeleton />
                        <DidomiTextInputField name="login.credentials.username" label="Username" placeholder="e.g. admin" required autocomplete="off" data-skeleton />
                        <DidomiPasswordInputField
                          name="login.credentials.password"
                          label="Password"
                          placeholder="e.g. password"
                          required
                          autocomplete={'new-password' as any}
                          hideShowPasswordButton={true}
                          onFocusin={handlePasswordFocusIn}
                          onFocusout={handlePasswordFocusOut}
                          data-skeleton
                        />
                        <DidomiTextInputField name="login.selectors.usernameField" label="Username CSS selector" placeholder="e.g. #username-input" data-skeleton />
                        <DidomiTextInputField name="login.selectors.passwordField" label="Password CSS selector" placeholder="e.g. #password-input" data-skeleton />
                        <DidomiTextInputField name="login.selectors.submitButton" label="Submit button CSS selector" placeholder="e.g. #submit-btn" data-skeleton />
                      </>
                    )}
                  </div>
                </DidomiCollapsibleSection>
              )}

              <DidomiHintbox titleText="Confirm you have the right to add this domain on the Didomi platform" variant="neutral" className="mt-xxs mb-xs">
                <p>Our bot will keep it monitored and will perform several test on it, you need the owner authorisation.</p>
              </DidomiHintbox>
              <DidomiCheckboxField
                name="isOwner"
                data-testid="owner-checkbox"
                label="I am the owner of this domain or acting on behalf of its owner (mandatory) *"
                checked={values.isOwner}
                onValueChange={(event: CustomEvent<boolean>) => setFieldValue('isOwner', event.detail)}
                required
                data-skeleton
              ></DidomiCheckboxField>
              <div className="w-full flex justify-end mt-5 mb-10">
                <DidomiButton
                  type="button"
                  variant="secondary"
                  className="mr-xs"
                  data-testid="cancel-button"
                  onClick={() => navigateTo('agnostik-compliance-report/')}
                  data-skeleton
                >
                  Cancel
                </DidomiButton>
                <DidomiButton type="button" iconRight="save" data-testid="save-button" onClick={submitForm} data-skeleton>
                  Save
                </DidomiButton>
              </div>
            </Form>
          </DidomiSkeleton>
        )}
      </Formik>
    </section>
  );
};
