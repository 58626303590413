import { useNavigate } from 'react-router-dom';
import React, { cloneElement } from 'react';
import { DidomiBackButtonLink, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';

interface IProps {
  children: any;
  loading?: boolean;
}

const WrapperComponent = ({ children }) => {
  return cloneElement(children);
};

/**
 * Add domain Layout
 */
export const AddDomainLayout = ({ children }: IProps) => {
  const navigate = useNavigate();
  const currentURL = window.location.href;

  return (
    <div>
      <section className="p-l pb-20 h-full box-border !flex flex-col">
        <div>
          <DidomiSecondaryHeader data-skeleton>
            <div slot="header-title" className="didomi-secondary-header-v2 header didomi-secondary-header-title-text" style={{ maxWidth: '100%' }}>
              {currentURL.includes('edit-domain') ? 'Editing a domain' : 'Adding a domain for the Compliance Reports'}
            </div>
            <div slot="description">
              {currentURL.includes('edit-domain') ? 'Changes and new scan will apply in the next hour.' : 'Monthly reports will be generated for this domain.'}
            </div>

            <div slot="back-button">
              <DidomiBackButtonLink className="cursor-pointer	" text="Back domain list" data-testid="detail-back-button" onClick={() => navigate('/')}></DidomiBackButtonLink>
            </div>
          </DidomiSecondaryHeader>
        </div>
        <WrapperComponent>{children}</WrapperComponent>
      </section>
    </div>
  );
};
