import { useState, useEffect } from 'react';
import { SortConfig } from '@types';
import { paginateAndSearchItems } from '../workers/searchItems';

type PaginateAndSearchOptions<T> = {
  search?: string;
  searchFields?: { field: string; transform?: (value: string) => string }[];
  translatableSearchFields?: string[];
  limit: number;
  page: number;
  filters?: Array<any>;
  sortConfig: SortConfig<T>;
};
const DEFAULT_SORT_CONFIG: SortConfig<unknown> = { field: 'name', dir: 'asc' };

export const usePaginatedFilteredItems = <T, Z>(items: T[], options: PaginateAndSearchOptions<Z>, tabTable) => {
  const [displayItems, setDisplayItems] = useState<T[]>([]);
  const [filteredItems, setFilteredItems] = useState<T[]>([]);
  const [paginationTotal, setPaginationTotal] = useState<number>(10);

  const { search, searchFields, translatableSearchFields, filters, page, limit, sortConfig } = options;
  useEffect(() => {
    if (!items?.length) {
      setPaginationTotal(0);
      return;
    }

    const { data, total, totalItems } = paginateAndSearchItems(items, {
      search: search || '',
      searchFields: searchFields || [],
      translatableSearchFields,
      page,
      limit,
      sortConfig: sortConfig || DEFAULT_SORT_CONFIG,
      filters,
    }) as { data: T[]; total: number; totalItems: T[] };
    setDisplayItems(data);
    setFilteredItems(totalItems);
    setPaginationTotal(total);
  }, [items, search, searchFields, translatableSearchFields, page, limit, sortConfig, filters, tabTable]);

  return {
    data: displayItems,
    total: paginationTotal,
    totalItems: filteredItems,
  };
};
