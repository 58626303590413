import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { DidomiTableRow, DidomiTableTd, DidomiButton, DidomiModal, DidomiLoader, DidomiModalContent, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';

import { useQueryClient } from 'react-query';
import { useSessionStorage } from 'usehooks-ts';
import { useEditVendor, useCreateVendor } from '@hooks';
import { Partner } from '@types';
import { ellipsis, handleClickOnMatchFromId, returnTypeOfVendor } from '@utils';
interface ComplianceReportMatchDomainToVendorTableRowProps {
  vendors: Partner[];
}
const ComplianceReportMatchDomainToVendorTableRow = ({ vendors }: ComplianceReportMatchDomainToVendorTableRowProps) => {
  const { mutateAsync: editVendor, isLoading: isEditing } = useEditVendor();
  const { mutateAsync: createVendor, isLoading: isCreating } = useCreateVendor();
  const navigate = useNavigate();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();
  const { reportId, propertyId } = useParams();
  const [storedUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);

  const selectedVendorDomains = useMemo(() => {
    return !storedUnknownVendorDomains ? [] : storedUnknownVendorDomains.map(vendor => vendor.url_sld);
  }, [storedUnknownVendorDomains]);

  const typeOfVendor = vendor => {
    return returnTypeOfVendor(vendor);
  };

  const redirectBack = async () => {
    await queryClient.resetQueries();
    navigate(`/domain/${propertyId}/match-unknown-domains/${reportId}`);
  };

  const clickAndMatchFromId = async (id: string) => {
    handleClickOnMatchFromId({
      editVendor,
      createVendor,
      redirectBack,
      queryClient,
      vendors,
      id,
      organizationId,
      selectedVendorDomains,
    });
  };

  const handlePurposesText = purposes => {
    if (!purposes) {
      return {
        title: '-',
        items: [],
      };
    }
    const purposeArray = purposes.split(',');
    return {
      title: ellipsis(purposeArray[0], 12),
      items: purposeArray,
    };
  };

  return (
    <>
      <DidomiModal isOpen={isEditing || isCreating}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{'Updating vendors...'}</DidomiModalContent>
      </DidomiModal>
      {vendors.map((vendor: Partner, idx) => {
        return (
          <>
            <DidomiTableRow key={idx} data-skeleton>
              <DidomiTableTd>
                <td>{vendor.name}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>{typeOfVendor(vendor) === 'Custom' ? vendor.id : ''}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>{typeOfVendor(vendor) === 'Custom' ? vendor.sdk_id : ''}</td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {typeOfVendor(vendor) === 'Custom' ? (
                    <td className="text-primary-blue-6">Custom</td>
                  ) : typeOfVendor(vendor) === 'Model' || typeOfVendor(vendor) === 'IAB' || typeOfVendor(vendor) === 'ATP' ? (
                    <td className="text-primary-pink-5">Model</td>
                  ) : (
                    <td></td>
                  )}
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {vendor.default_purposes_id?.length ? handlePurposesText(vendor.default_purposes_id.toString()).title : '-'}

                  <>
                    <DidomiTooltip contentElementId={`purpose_id_${vendor.id}`} variant="helper" placement="top">
                      <span className="text-neutral-gray-6 cursor-pointer">
                        {' '}
                        {handlePurposesText(vendor?.default_purposes_id?.toString()).items.length > 1
                          ? `+ (${handlePurposesText(vendor?.default_purposes_id?.toString()).items.length - 1})`
                          : ''}
                      </span>
                    </DidomiTooltip>
                    <div style={{ display: 'none' }} id={`purpose_id_${vendor.id}`}>
                      {handlePurposesText(vendor?.default_purposes_id?.toString()).items.map((item, k) => {
                        return <p key={k}>- {item}</p>;
                      })}
                    </div>
                  </>
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  {vendor.legitimate_interest_purposes_id?.length ? handlePurposesText(vendor.legitimate_interest_purposes_id?.toString()).title : '-'}{' '}
                  <>
                    <DidomiTooltip contentElementId={`legint_id_${vendor.id}`} variant="helper" placement="top">
                      <span className="text-neutral-gray-6 cursor-pointer">
                        {' '}
                        {handlePurposesText(vendor?.legitimate_interest_purposes_id?.toString()).items.length > 1
                          ? `+ (${handlePurposesText(vendor?.default_purposes_id?.toString()).items.length - 1})`
                          : ''}
                      </span>
                    </DidomiTooltip>
                    <div style={{ display: 'none' }} id={`legint_id_${vendor.id}`}>
                      {handlePurposesText(vendor?.legitimate_interest_purposes_id?.toString()).items.map((item, k) => {
                        return <p key={k}>- {item}</p>;
                      })}
                    </div>
                  </>
                </td>
              </DidomiTableTd>
              <DidomiTableTd>
                <td>
                  <DidomiButton variant="secondary" size="small" onClick={() => clickAndMatchFromId(vendor.id)}>
                    Match to this vendor
                  </DidomiButton>
                </td>
              </DidomiTableTd>
            </DidomiTableRow>
          </>
        );
      })}
    </>
  );
};

export { ComplianceReportMatchDomainToVendorTableRow };
