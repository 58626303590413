import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, MemberPropertyNotification } from '@types';

export const useMemberPropertyNotifications = (options?: UseQueryOptions<PaginatedResponse<MemberPropertyNotification>>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchMemberPropertyNotifications = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<MemberPropertyNotification>>(apiRoutes.memberPropertyNotifications, {
      params: { organization_id: organizationId },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<MemberPropertyNotification>, AxiosError>(['member-property-notifications'], fetchMemberPropertyNotifications, options);
};
