import React from 'react';
import { DidomiButton, DidomiHintbox, DidomiIcon } from '@didomi/ui-atoms-react';
import { ScenarioActionType, ScenarioType } from '@enums';
import { CustomScenario } from '@types';
import { ScenarioCard } from './components/ScenarioCard/ScenarioCard';

interface ScenariosSectionProps {
  scenarios: CustomScenario[];
  hasComplianceReportPremiumFeature: boolean;
  onEnabledChange?: (value: boolean, index: number) => void;
  onAddScenario?: () => void;
  onDeleteScenario?: (index: number) => void;
  onNameChange?: (value: string, index: number) => void;
  onAddAction?: (scenarioIndex: number, action: ScenarioActionType) => void;
  onDeleteAction?: (scenarioIndex: number, actionIndex: number) => void;
  onMoveAction?: (scenarioIndex: number, fromActionIndex: number, toActionIndex: number) => void;
  onCssSelectorChange?: (scenarioIndex: number, actionIndex: number, value: string) => void;
  onInputValueChange?: (scenarioIndex: number, actionIndex: number, value: string) => void;
}

const SCENARIO_NAME_BY_TYPE = {
  [ScenarioType.ACCEPT_ALL]: 'Accept all',
  [ScenarioType.REFUSE_ALL]: 'Refuse all',
  [ScenarioType.NO_ACTIONS]: 'No actions',
};

const SCENARIO_AVAILABLE_ACTIONS_BY_TYPE = {
  [ScenarioType.ACCEPT_ALL]: [ScenarioActionType.CLICK, ScenarioActionType.FILL_IN],
  [ScenarioType.REFUSE_ALL]: [ScenarioActionType.CLICK, ScenarioActionType.FILL_IN],
  [ScenarioType.NO_ACTIONS]: [ScenarioActionType.CLICK, ScenarioActionType.FILL_IN],
  [ScenarioType.CUSTOM]: [ScenarioActionType.ACCEPT, ScenarioActionType.REFUSE, ScenarioActionType.CLICK, ScenarioActionType.FILL_IN],
};

const NON_DELETABLE_ACTIONS_BY_SCENARIO_TYPE = {
  [ScenarioType.ACCEPT_ALL]: [ScenarioActionType.ACCEPT],
  [ScenarioType.REFUSE_ALL]: [ScenarioActionType.REFUSE],
  [ScenarioType.NO_ACTIONS]: [],
  [ScenarioType.CUSTOM]: [],
};

export const ScenariosSection = ({
  scenarios,
  hasComplianceReportPremiumFeature,
  onEnabledChange,
  onAddScenario,
  onDeleteScenario,
  onNameChange,
  onAddAction,
  onDeleteAction,
  onMoveAction,
  onCssSelectorChange,
  onInputValueChange,
}: ScenariosSectionProps) => {
  const totalEnabledScenarios = scenarios?.filter(scenario => scenario.enabled).length;

  return (
    <div className="mb-s" data-testid="scenarios-section">
      <DidomiHintbox titleText="Bot’s scenarios" variant="neutral" className="mt-xxs mb-s">
        <div>
          {hasComplianceReportPremiumFeature && (
            <p>The bot can run different scenarios: the more scenarios the more comprehensive the analysis is. You can customise the exiting scenarios or create new ones.</p>
          )}
          {!hasComplianceReportPremiumFeature && (
            <p>
              By default, scans are run using the &quot;Accept all&quot; scenario. Additional scanning scenarios are available with Advanced Compliance Monitoring. To enable,
              please contact your Customer Success Manager.
            </p>
          )}
        </div>
      </DidomiHintbox>

      {scenarios?.map((scenario, index) => {
        return (
          <ScenarioCard
            key={index}
            className="mb-xxs"
            name={scenario.name || SCENARIO_NAME_BY_TYPE[scenario.type]}
            enabled={scenario.enabled}
            editableName={scenario.type === ScenarioType.CUSTOM}
            showDeleteScenarioButton={scenario.type === ScenarioType.CUSTOM}
            availableActions={hasComplianceReportPremiumFeature ? SCENARIO_AVAILABLE_ACTIONS_BY_TYPE[scenario.type] : []}
            selectedActions={scenario.scenario_actions}
            hideDeleteButtonForActions={NON_DELETABLE_ACTIONS_BY_SCENARIO_TYPE[scenario.type]}
            disableCheckbox={(scenario.enabled && totalEnabledScenarios === 1) || (!scenario.enabled && totalEnabledScenarios === 3)}
            onEnabledChange={value => onEnabledChange(value, index)}
            onDeleteScenario={() => onDeleteScenario(index)}
            onNameChange={value => onNameChange(value, index)}
            onAddAction={action => onAddAction(index, action)}
            onDeleteAction={actionIndex => onDeleteAction(index, actionIndex)}
            onMoveAction={(fromIndex, toIndex) => onMoveAction(index, fromIndex, toIndex)}
            onCssSelectorChange={(actionIndex, value) => onCssSelectorChange(index, actionIndex, value)}
            onInputValueChange={(actionIndex, value) => onInputValueChange(index, actionIndex, value)}
          />
        );
      })}

      {hasComplianceReportPremiumFeature && (
        <DidomiButton size="small" onClick={onAddScenario} disabled={scenarios?.length === 6} data-skeleton>
          <div className="flex items-center gap-xxs">
            <DidomiIcon name="add" />
            <span>Add scenario</span>
          </div>
        </DidomiButton>
      )}
    </div>
  );
};
