import { useActiveOrganization, useAuthToken } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { CollectedVendors } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useCollectedVendorsFromCr = ({ ids, unmatchedDomainsOnly = false }) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const token = useAuthToken();
  const fetchVendorsFromReportIds = async (ids: string[], unmatchedDomainsOnly: boolean) => {
    if (ids) {
      const response = await axiosWithInterceptors.post<CollectedVendors[]>(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/collected-vendors?organization_id=${organizationId}&bypassMetadataPartnersMapping=false`,
        {
          report_ids: ids,
          unmatchedDomainsOnly,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    }
  };
  const results = useQuery<CollectedVendors[], AxiosError>([`reports-collected-vendors`, ids], () => fetchVendorsFromReportIds(ids, unmatchedDomainsOnly), {});

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
