import * as Yup from 'yup';

export const PROPERTY_SCHEMA = Yup.object().shape({
  isOwner: Yup.boolean().oneOf([true], 'This is required'),
  name: Yup.string().trim().required('Name is required'),
  website: Yup.string().url('You must provide a valid URL (e.g. https://www.myDomain.com)').required('Website is required'),
  pages_count: Yup.number()
    .required()
    .test('is-num-1-20', 'The number of pages* must be between 1 and 20', function (value) {
      return value >= 1 && value <= 20;
    }),
  filterValue: Yup.string().nullable(),
  filterType: Yup.boolean().nullable(),
  schedule: Yup.string().nullable().default('Monthly'),
  user_agent: Yup.string().nullable(),
  login: Yup.object().shape({
    enabled: Yup.boolean().nullable(),
    selectors: Yup.object({
      pathToForm: Yup.string().when('$login.enabled', {
        is: true,
        then: schema => schema.url('You must provide a valid URL (e.g. https://www.myDomain.com/login)').required('Form URL is required'),
        otherwise: schema => schema.nullable(),
      }),
      usernameField: Yup.string().nullable(),
      passwordField: Yup.string().nullable(),
      submitButton: Yup.string().nullable(),
    }),
    credentials: Yup.object().shape({
      username: Yup.string().when('$login.enabled', {
        is: true,
        then: schema => schema.required('Username is required'),
        otherwise: schema => schema.nullable(),
      }),
      password: Yup.string().when('$login.enabled', {
        is: true,
        then: schema => schema.required('Password is required'),
        otherwise: schema => schema.nullable(),
      }),
    }),
  }),
});

export type PropertySchema = Yup.InferType<typeof PROPERTY_SCHEMA>;
