import React, { useState } from 'react';
import { useIsOverflowing } from '@didomi/helpers-react';
import { DidomiTooltip } from '@didomi/ui-atoms-react';

interface Props {
  'data-testid'?: string;
  value: string;
  className?: string;
}

export const TruncatedTextWithTooltip = ({ 'data-testid': dataTestId = 'truncated-text', value, className = '' }: Props) => {
  const [spanRef, setSpanRef] = useState<HTMLSpanElement>();
  const isOverflowing = useIsOverflowing(spanRef);

  return (
    <DidomiTooltip className="max-w-full" data-testid={`${dataTestId}-tooltip`} content={isOverflowing ? value : ''} placement="right">
      <span data-testid={`${dataTestId}-text${isOverflowing ? '-truncated' : ''}`} ref={setSpanRef} className={`inline-block truncate w-full py-1 ${className}`}>
        {value}
      </span>
    </DidomiTooltip>
  );
};
