import React from 'react';
import { DidomiIllustration, DidomiTableTd } from '@didomi/ui-atoms-react';

export const ComplianceReportDetailTableEmptyRow = () => {
  return (
    <DidomiTableTd>
      <div data-testid="row-no-match-found" className="h-[400px] w-full flex-1 flex flex-col items-center justify-center">
        <div className="font-sans text-body-big font-semibold text-primary-blue-4 mb-1">Sorry,</div>
        <h3 className="font-serif text-xl text-primary-blue-6 mb-4">No match found</h3>
        <DidomiIllustration name="traces-no-match-found" />
      </div>
    </DidomiTableTd>
  );
};
