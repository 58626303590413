import { ScenarioActionType, ScenarioType } from '@enums';
import { CustomScenario } from '@types';

/**
 * Omit generated fields from scenarios
 * @param scenarios scenarios to omit generated fields from
 * @returns scenarios with generated fields omitted
 */
export const omitScenariosGeneratedFields = (scenarios: CustomScenario[]) => {
  if (!Array.isArray(scenarios)) return [];

  return scenarios.map(scenario => {
    const { id, created_at, updated_at, property_id, name, ...rest } = scenario;
    return {
      ...rest,
      ...(scenario.type === ScenarioType.CUSTOM ? { name } : {}),
      scenario_actions: rest.scenario_actions.map(action => {
        const { id, created_at, updated_at, scenario_id, css_selector, input_value, ...rest } = action;
        return {
          ...rest,
          ...(action.type === ScenarioActionType.CLICK ? { css_selector } : {}),
          ...(action.type === ScenarioActionType.FILL_IN ? { css_selector, input_value } : {}),
        };
      }),
    };
  });
};
