import { useNavigate } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DidomiAutocompleteCustomEvent } from '@didomi/ui-atoms';
import { DidomiAutocomplete, DidomiButton, DidomiChip, DidomiChipList, DidomiErrorState, DidomiSelectOption, DidomiSelectOptions, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useFeatureFlag, useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { homeRoute } from '@constants';
import { useAccessPolicies, useCreateMemberPropertyNotification, useDeleteMemberPropertyNotification, useMemberPropertyNotifications, useMembers } from '@hooks';
import { MemberPropertyNotification, PaginatedResponse } from '@types';
import { Member } from '../../interfaces';

/**
 * Alerting Settings Page
 */
export const AlertingSettings = () => {
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [currentlySubscribedMemberIds, setCurrentlySubscribedMemberIds] = useState<string[]>([]);
  const {
    refetch: membersRefetch,
    isError: membersError,
    isLoading: membersLoading,
    data: { data: members } = {},
  } = useMembers({
    refetchOnMount: 'always',
  });
  const {
    refetch: memberPropertyNotificationsRefetch,
    isError: memberPropertyNotificationsError,
    isLoading: memberPropertyNotificationsLoading,
    data: { data: memberPropertyNotifications } = {},
  } = useMemberPropertyNotifications({
    refetchOnMount: 'always',
    onSuccess: (response: PaginatedResponse<MemberPropertyNotification>) => {
      if (response?.data?.length) {
        setCurrentlySubscribedMemberIds(response.data.map(({ member_id }) => member_id).filter(Boolean) || []);
      }
    },
  });

  const { isLoading: isAccessPoliciesLoading, hasAdminAccess } = useAccessPolicies();
  const [hasAdvancedComplianceMonitoringAccess] = useFeatureFlag('compliance_report_v2');
  const isLoading = membersLoading || memberPropertyNotificationsLoading || isAccessPoliciesLoading;
  const isError = membersError || memberPropertyNotificationsError;

  const { mutateAsync: deleteMemberPropertyNotification, isLoading: memberPropertyNotificationDeleting } = useDeleteMemberPropertyNotification();

  const { mutateAsync: createMemberPropertyNotification, isLoading: memberPropertyNotificationUpdateSaving } = useCreateMemberPropertyNotification();

  const initiallySubscribedMemberIds = useMemo(() => {
    return (memberPropertyNotifications && memberPropertyNotifications.map(({ member_id }) => member_id)) || [];
  }, [memberPropertyNotifications]);

  const isSubscribedMembersListDirty = useMemo(() => {
    return (
      initiallySubscribedMemberIds.length !== currentlySubscribedMemberIds.length || initiallySubscribedMemberIds.some(memberId => !currentlySubscribedMemberIds.includes(memberId))
    );
  }, [initiallySubscribedMemberIds, currentlySubscribedMemberIds]);

  const isSaveButtonDisabled = memberPropertyNotificationUpdateSaving || memberPropertyNotificationDeleting || isLoading || !isSubscribedMembersListDirty;

  useLayoutEffect(() => {
    if (isAccessPoliciesLoading) return;

    if (!(hasAdvancedComplianceMonitoringAccess && hasAdminAccess)) {
      navigate(homeRoute);
    }
  }, [hasAdvancedComplianceMonitoringAccess, hasAdminAccess, navigate, isAccessPoliciesLoading]);

  const memberExists = (memberId: string) => !!members?.find(member => member.id === memberId);

  const handleSave = async () => {
    // subscribe new members that do not appear in the original subscription list
    // by affecting each member to all properties (property_id = NULL)
    const newlySubscribedMembersIds: string[] = currentlySubscribedMemberIds.filter(memberId => !initiallySubscribedMemberIds.includes(memberId));
    const createMemberPropertyNotificationPromises = newlySubscribedMembersIds.map(memberId => createMemberPropertyNotification({ member_id: memberId, property_id: null }));

    // remove members that no longer appear in the original subscription list
    // by deleting their related 'member-property-notification' subscription
    const previouslySubscribedMembersIds: string[] = initiallySubscribedMemberIds.filter(memberId => !currentlySubscribedMemberIds.includes(memberId));
    const legacySubscriptions = memberPropertyNotifications.filter(memberPropertyNotification => previouslySubscribedMembersIds.includes(memberPropertyNotification.member_id));
    const deleteMemberPropertyNotificationPromises = legacySubscriptions.map(({ id }) => deleteMemberPropertyNotification({ id }));

    try {
      await Promise.all([...createMemberPropertyNotificationPromises, ...deleteMemberPropertyNotificationPromises]);
      await displaySnackbar('Members have been saved!', { icon: 'check' });
    } catch (error) {
      await displaySnackbar('There was an error saving the members list: ' + error.message, { variant: 'error' });
    } finally {
      refetch();
    }
  };

  const onMembersSelect = (e: DidomiAutocompleteCustomEvent<string[]>) => {
    setCurrentlySubscribedMemberIds(e.detail.filter(memberExists));
  };

  const handleSearchChange = async (e: CustomEvent<string>) => {
    const searchQuery = e.detail.toLowerCase();

    if (!searchQuery) {
      setFilteredMembers(members);
    }

    const filteredMembers = members.filter(member => member.user.name.toLowerCase().includes(searchQuery));
    setFilteredMembers(filteredMembers);
  };

  useEffect(() => {
    if (members?.length > 0) {
      setFilteredMembers(members);
    }
  }, [members]);

  // We wrap the chiplist, otherwise the page can break when navigating back and forth
  const ChipList = () => (
    <DidomiChipList>
      {currentlySubscribedMemberIds?.map(memberId => (
        <DidomiChip variation="basic" data-testid={'member-chip-' + memberId} key={memberId} label={members?.find(member => member.id === memberId)?.user?.name}></DidomiChip>
      ))}
    </DidomiChipList>
  );

  const refetch = () => {
    membersRefetch();
    memberPropertyNotificationsRefetch();
  };

  if (isError) {
    return (
      <DidomiErrorState illustration-name="content-loading-error" action-name="Reload" action-icon="reset" className="w-full h-full" onActionClick={refetch}>
        <div slot="title">It seems to be an error</div>
        <div slot="description">The action could not be completed.</div>
      </DidomiErrorState>
    );
  }

  return (
    <div className="w-[625px]">
      <div className="flex flex-col">
        <span className="text-primary-blue-6 font-semibold leading-5 mb-xxs">Email notification</span>

        <span className="text-primary-blue-6 text-body-small leading-5 mb-xxs">
          Selected users will receive an email once a week with the latest updates on the compliance reports
        </span>

        <div className="w-full">
          {isLoading && (
            <>
              <DidomiSkeleton isLoading={true} className="h-[65px] w-full mb-[22px] rounded-[4px] mt-4" data-testid="settings-select-loading"></DidomiSkeleton>

              <DidomiSkeleton isLoading={true} className="h-[26px] w-full mb-7 rounded-[8px]" data-testid="settings-chips-loading"></DidomiSkeleton>
            </>
          )}

          {!isLoading && (
            <>
              <DidomiAutocomplete
                data-testid="members-autocomplete"
                label="Notified users"
                placeholder={'Select users to notify'}
                name="widget-domain"
                multi={true}
                className="mt-4"
                data-skeleton
                value={currentlySubscribedMemberIds}
                onValueChange={onMembersSelect}
                onSearchChange={handleSearchChange}
              >
                <DidomiSelectOptions>
                  {filteredMembers &&
                    filteredMembers?.map(member => (
                      <DidomiSelectOption label={member.user.name} value={member.id} key={member.id} selected={currentlySubscribedMemberIds.includes(member.id)} />
                    ))}
                </DidomiSelectOptions>
              </DidomiAutocomplete>

              <div className={tx({ 'mb-7': currentlySubscribedMemberIds?.length > 0 })}>
                <ChipList />
              </div>
            </>
          )}

          <div>
            <DidomiButton variant="secondary" data-testid="settings-cancel-button" className={tx('mr-xs')} data-skeleton onClick={() => navigate(homeRoute)}>
              Cancel
            </DidomiButton>

            <DidomiButton data-testid="settings-save-button" disabled={isSaveButtonDisabled} iconRight="save" className="mr-xs" onClick={handleSave} data-skeleton>
              Save
            </DidomiButton>
          </div>
        </div>
      </div>
    </div>
  );
};
