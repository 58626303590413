import { TrackerType } from '@enums';

export const TRACKER_TYPE_TO_LABEL: Record<TrackerType, string> = {
  [TrackerType.PIXEL]: 'Pixel',
  [TrackerType.COOKIE]: 'Cookie',
  [TrackerType.WEB_STORAGE]: 'Web storage',
  [TrackerType.INDEXED_DB]: 'Indexed DB',
};

export const getTrackerTypeValue = (type: TrackerType): string => {
  return TRACKER_TYPE_TO_LABEL[type] || '';
};
