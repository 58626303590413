import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

type Tab = 'vendor' | 'issue' | 'tracker';

enum tabValues {
  'tracker' = 0,
  'vendor' = 1,
  'issue' = 2,
}

const defaultActiveTab = {
  defaultTab: 'default',
};

interface PaginationOptions {
  defaultTab?: Tab;
}

export const useDetailTabActiveStatus = (options?: PaginationOptions) => {
  const { defaultTab } = {
    ...defaultActiveTab,
    ...options,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = useMemo(() => {
    return tabValues[searchParams.get('activeTab')] || tabValues[defaultTab];
  }, [searchParams, defaultTab]);

  useEffect(() => {
    if (!searchParams.get('activeTab')) {
      searchParams.set('activeTab', tabValues[0]);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const setActiveTab = useCallback(
    (selectedTab: number) => {
      searchParams.set('activeTab', tabValues[selectedTab]);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    activeTab,
    setActiveTab,
  };
};
