import { AggregatedTracker, Cookie } from '@types';
import { getTrackerAgeInMonthOrDays, getTrackerAgeInMonthsNumeric, getInitiatorVendorIdentifier, getInitiatorVendorLabel, getVendorIdentifier, getVendorLabel } from '@utils';

/**
 * Defines the trackers data based on the last report
 *
 * @param {Cookie[]} cookies - The report containing tracker information
 * @returns {AggregatedTracker[]} - The list of aggregated trackers
 */
export const defineTrackersTableData = (cookies: Cookie[]): AggregatedTracker[] => {
  const list = [];
  cookies.forEach(cookie => {
    const draftTracker = {
      id: cookie.id,
      name: cookie.name,
      sld: cookie.sld || null,
      domain: cookie.domain,
      host: cookie.host,
      party: cookie.is_third_party ? '3rd party' : '1st party',
      first_party: !cookie.is_third_party,
      initiator: {
        identifier: getInitiatorVendorIdentifier(cookie),
        label: getInitiatorVendorLabel(cookie),
      },
      vendor: {
        identifier: getVendorIdentifier(cookie),
        label: getVendorLabel(cookie),
      },
      type: cookie.type,
      max_lifetime_seconds: cookie.max_lifetime_seconds ? parseInt(cookie.max_lifetime_seconds) : 0,
      lifetime: getTrackerAgeInMonthOrDays(cookie.max_lifetime_seconds),
      lifetime_in_months_numeric: getTrackerAgeInMonthsNumeric(cookie.max_lifetime_seconds),
      scenarios: cookie.scenarios,
      ran_scenarios: cookie?.ran_scenarios,
      functional_scenario: cookie?.functional_scenario,
      is_regex: cookie?.is_regex || false,
      is_exempt: cookie?.is_exempt || false,
      exemption_category_id: cookie?.exemption_category_id,
      purposes: cookie?.purposes,
      purpose_ids: cookie?.purpose_ids,
    } as AggregatedTracker;
    list.push(draftTracker);
  });
  return list as AggregatedTracker[];
};
