import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { Property } from '../types/responses/property';

/**
 * Creates a vendor, makes sure to update the list of vendors with the new added vendor
 * */
export const useCreateDomain = (options?: UseMutationOptions<AxiosResponse<Property>, AxiosError, unknown>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const { organizationId } = useActiveOrganization();

  return useMutation(
    async (payload: Partial<Property>) => {
      return axiosWithInterceptors.post<Property>(
        `${apiRoutes.crProperties}?organization_id=${organizationId}`,
        {
          ...payload,
          enabled: true,
        },
        {},
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.refetchQueries('properties-v2');
        options?.onSuccess(...args);
      },
    },
  );
};
