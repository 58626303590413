import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiCardContainer,
  DidomiIcon,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTooltip,
  DidomiTabHeader,
  DidomiTabHeaders,
  DidomiTabs,
  DidomiTabPanels,
  DidomiTabPanel,
  DidomiTabHeading,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { useWorker } from '@shopify/react-web-worker';
import { createWorkerFactory } from '@shopify/web-worker';
import {
  ComplianceIndexCircle,
  ComplianceReportCoreTrackerTable,
  ComplianceReportCoreVendorTable,
  ComplianceReportDetailComplianceIssues,
  ComplianceReportMenuAction,
  ComplianceReportVendorGraph,
} from '@components';
import { PropertyWithOptions, ReportRequestType, ReportStatus } from '@enums';
import { usePropertyReport, useAccessPolicies, useDetailTabActiveStatus, usePropertyTrackerSettings, useCustomAndIABPurposes, useReportStatusCheck } from '@hooks';
import { SortConfig, Report } from '@types';
import { alphaComplianceIndex, convertReports, formatDate, getLastAnalyzedReport, orderReportsByDate } from '@utils';
import { useProperty } from '../../hooks/data/properties/useProperty.hook';
import { useReports } from '../../hooks/data/reports/useReports.hook';

const createWorker = createWorkerFactory(() => import('../../utils/nodesAndEdgesFromRequestsAndTrackers'));

const FINAL_REPORT_STATES = [ReportStatus.ANALYZED, ReportStatus.FAILURE];

const SORT: SortConfig<keyof Report> = {
  field: 'modified',
  dir: 'desc',
};

const ComplianceReportDetail = () => {
  const params = useParams();

  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/agnostik-compliance-report');
  const { nodesAndEdgesFromRequestsAndTrackers } = useWorker(createWorker);

  const [isProcessingNodesAndEdges, setIsProcessingNodesAndEdges] = useState(false);
  const [nodeAndEdges, setNodeAndEdges] = useState<any>({});
  const [displayGraph, setDisplayGraph] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [hideError, setHideError] = useState(false);
  const { activeTab = 0, setActiveTab } = useDetailTabActiveStatus();

  const { hasComplianceReportEditAccess } = useAccessPolicies();

  const { isLoading: isLoadingProperty, data: property, refetch: refetchProperty } = useProperty({ id: params.propertyId, with: [PropertyWithOptions.LATEST_REPORTS] });

  const { isLoading: reportLoading, data: report } = usePropertyReport({
    enabled: !!reportId,
    reportId,
  });
  const { isLoading: isLoadingPurposes, data: purposesToSelect } = useCustomAndIABPurposes();
  const { isLoading: isLoadingTrackerSettings, data: propertyTrackerSettings } = usePropertyTrackerSettings({
    propertyId: params.propertyId,
  });

  const {
    isLoading: isLoadingReports,
    data: { data: reports } = {},
    refetch: refetchReports,
  } = useReports({
    propertyId: params.propertyId,
    limit: 1000,
    sort: SORT,
  });

  const { includeReportsToCheck } = useReportStatusCheck({
    withStatus: FINAL_REPORT_STATES,
    onReportsChange: () => {
      refetchProperty();
      refetchReports();
    },
  });

  const orderedReports = useMemo(() => {
    if (!reports?.length) return [];

    const convertedReports = convertReports(reports);

    return orderReportsByDate(convertedReports);
  }, [reports]);

  const isErrorOrLoading = useMemo(() => {
    const reportStatus = orderedReports[0]?.status;
    if (reportId) return hideError ? false : reportStatus === ReportStatus.FAILURE || reportStatus !== ReportStatus.ANALYZED;
    return true;
  }, [orderedReports, reportId, hideError]);

  const selectedReport = useMemo(() => {
    return orderedReports.find(report => report.id === reportId);
  }, [orderedReports, reportId]);

  useEffect(() => {
    if (orderedReports?.length) {
      includeReportsToCheck([orderedReports[0]]);

      const lastAnalyzedReport = getLastAnalyzedReport({ latest_reports: orderedReports });
      setReportId(lastAnalyzedReport?.id || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedReports]);

  useEffect(() => {
    const processNodeAndEdges = async () => {
      setIsProcessingNodesAndEdges(true);
      const requests = report?.details_json?.requests || [];
      const trackers = report?.details_json?.property.cookies || [];
      const nodeAndEdges = await nodesAndEdgesFromRequestsAndTrackers(requests, trackers);
      setNodeAndEdges(nodeAndEdges);
      setIsProcessingNodesAndEdges(false);
    };

    if (report?.id) {
      processNodeAndEdges();
    }
  }, [report, nodesAndEdgesFromRequestsAndTrackers]);

  const handleReportDateSelect = useCallback(event => {
    setReportId(event.detail);
  }, []);

  const handleTabChange = evt => {
    setActiveTab(evt.detail.active);
    if (evt.detail.active === 1) {
      setDisplayGraph(true);
    } else {
      setDisplayGraph(false);
    }
  };

  const isLoadingReady = isLoadingProperty || reportLoading || isLoadingTrackerSettings || isLoadingPurposes || isLoadingReports;

  return (
    <div className="pb-unsafe-bottom-space">
      <DidomiSkeleton isLoading={isLoadingReady} variant="layout" className="min-h-[60vh] w-full">
        <DidomiCardContainer className="mt-m relative w-full" style={{ '--card-inner-padding': '0px' }}>
          <div className="flex flex-row px-s py-s">
            <div className={`flex flex-col ${isErrorOrLoading ? 'w-1/2' : 'w-4/6'}`}>
              <div className="mb-[20px] flex flex-row items-start">
                <span className="body-normal-semibold text-primary-blue-6 mr-xxs items-center" data-skeleton>
                  Didomi rating
                </span>
                <div className="flex">
                  <DidomiTooltip
                    data-skeleton
                    slot="description"
                    variant="helper"
                    content="The Didomi Rating is an easy way to visualize your website's overall compliance performance"
                  >
                    <DidomiIcon name="helper-text"></DidomiIcon>
                  </DidomiTooltip>
                </div>
              </div>
              <div className="flex flex-row items-center">
                <ComplianceIndexCircle complianceIndex={report ? alphaComplianceIndex(report.score) : '-'} data-skeleton />
                <p className="ml-l" data-skeleton>
                  <span className="text-body-big text-primary-blue-6 font-bold">{report ? report?.trackers_count : '-'}</span>
                  <br />
                  <span className="text-primary-blue-6 row-text-primary">TRACKERS</span>
                </p>
                <div className="h-full w-[1px] bg-neutral-gray-3 ml-l mr-m"></div>
                <p data-skeleton>
                  <span className="text-body-big text-primary-blue-6 font-bold">{report ? (report.vendors_count || 0) + (report.unmatched_slds_count || 0) : '-'}</span>
                  <br />
                  <span className="row-text-primary text-primary-blue-6">VENDORS AND DOMAINS</span>
                </p>
              </div>
              <div className={`absolute right-[${isErrorOrLoading ? '51%' : '35%'}]`}>
                <ComplianceReportMenuAction
                  hasComplianceReportEditAccess={hasComplianceReportEditAccess}
                  menuKey={8749651}
                  hideRemove
                  hideDetail
                  reportId={reportId}
                  property={property}
                  isLoading={isLoadingReady}
                  onReportCreated={() => {
                    refetchProperty();
                    refetchReports();
                  }}
                />
              </div>
            </div>
            <div
              data-skeleton
              className={`flex flex-row ${isErrorOrLoading ? 'w-1/2' : 'w-2/6'} bg-neutral-gray-1`}
              style={{ border: '1px solid #C9DFE8', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}
            >
              <div className=" m-auto w-full flex flex-col items-center">
                <div className="flex relative">
                  <p className="absolute field-label left-[64px] top-[-34px]" style={{ marginTop: '16px' }}>
                    Pick a report
                  </p>
                  <DidomiSelect
                    data-testid="report-list-dropdown"
                    data-skeleton
                    className="px-xxl leading-[1px] neutral-gray-5"
                    style={{ width: window.screen.width > 1445 ? '450px' : '100%' }}
                    value={report?.id || ''}
                    onValueChange={handleReportDateSelect}
                    hideErrorMessage={true}
                  >
                    <DidomiSelectOptions>
                      {orderedReports.map((option, idx) =>
                        option.status === ReportStatus.ANALYZED ? (
                          <DidomiSelectOption
                            key={idx}
                            secondaryLabel={option.request_type === ReportRequestType.ON_DEMAND ? 'User requested' : ''}
                            label={formatDate(option.updated_at || option.created_at)}
                            value={option.id}
                          ></DidomiSelectOption>
                        ) : option.status === ReportStatus.FAILURE ? (
                          <DidomiSelectOption
                            className="cursor-not-allowed"
                            key={idx}
                            secondaryLabel={option.request_type === ReportRequestType.ON_DEMAND ? 'User requested' : ''}
                            disabled
                            label={formatDate(option.updated_at || option.created_at) + ' - Failed'}
                            value={option.id}
                          ></DidomiSelectOption>
                        ) : (
                          <DidomiSelectOption
                            key={idx}
                            secondaryLabel={option.request_type === ReportRequestType.ON_DEMAND ? 'User requested' : ''}
                            disabled
                            label={formatDate(option.updated_at || option.created_at) + ' - Report in progress'}
                            value={option.id}
                          ></DidomiSelectOption>
                        ),
                      )}
                    </DidomiSelectOptions>
                  </DidomiSelect>
                </div>
              </div>
              {![ReportStatus.ANALYZED, ReportStatus.FAILURE].includes(orderedReports[0]?.status) && (
                <div className="flex flex-col justify-around items-center pl-xs pr-xs pt-xs pb-xs min-w-[150px]" style={{ borderLeft: '1px solid #C9DFE8' }}>
                  <p className="font-semibold text-primary-blue-6 text-center " data-cy="report-loading-box">
                    A new report is beeing Generated
                  </p>
                  <p className="font-semibold text-primary-blue-4 text-[14px] mt-xxs mb-xxs text-center ">Usually in less than 15 mins</p>
                  <object type="image/svg+xml" data={`${ASSETS_URL}/assets/loadingAnimationLoop.svg`} width={64}>
                    Loading image
                  </object>
                </div>
              )}
              {orderedReports[0]?.status === ReportStatus.FAILURE && !hideError && (
                <div className="flex flex-col items-center justify-around pl-xs pr-xs relative min-w-[150px]" style={{ borderLeft: '1px solid #C9DFE8' }}>
                  <div className="flex flex-col items-center">
                    <DidomiIcon name="close-cancel" className="absolute top-[5px] right-[5px] cursor-pointer" onClick={() => setHideError(true)} />
                    <DidomiIcon name="failure" />
                    <p className="font-semibold text-primary-pink-5 text-[14px] mt-xxs mb-xxs text-center ">Last report failed</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DidomiCardContainer>

        <DidomiTabs active-tab={activeTab} className="mt-m" onActiveTabChange={handleTabChange}>
          <DidomiTabHeading variant="flow" className="mb-m">
            <DidomiTabHeaders data-skeleton>
              <DidomiTabHeader data-testid="tracker-tab" name="Trackers"></DidomiTabHeader>
              <DidomiTabHeader data-testid="vendor-tab" name="Vendors"></DidomiTabHeader>
              <DidomiTabHeader data-testid="issue-tab" name="Compliance issues"></DidomiTabHeader>
            </DidomiTabHeaders>
          </DidomiTabHeading>
          <DidomiTabPanels>
            <DidomiTabPanel>
              <div>
                {!isLoadingReady && (
                  <div>
                    <ComplianceReportCoreTrackerTable
                      report={report}
                      purposes={purposesToSelect}
                      propertyTrackerSettings={propertyTrackerSettings?.data}
                      isLoadingNodes={isProcessingNodesAndEdges}
                      nodes={nodeAndEdges.nodes}
                      key={`${reportId}-trackers`}
                    />
                  </div>
                )}
              </div>
            </DidomiTabPanel>
            <DidomiTabPanel>
              <div>
                {!isLoadingReady && (
                  <div>
                    <ComplianceReportCoreVendorTable report={report} isLoadingNodes={isProcessingNodesAndEdges} nodes={nodeAndEdges.nodes} key={`${reportId}-vendors`} />
                    {displayGraph && <ComplianceReportVendorGraph report={report} key={reportId} />}
                  </div>
                )}
              </div>
            </DidomiTabPanel>
            <DidomiTabPanel>
              <div>
                {!isLoadingReady && (
                  <ComplianceReportDetailComplianceIssues
                    report={report}
                    purposes={purposesToSelect}
                    propertyTrackerSettings={propertyTrackerSettings?.data}
                    isLoadingNodes={isProcessingNodesAndEdges}
                    nodes={nodeAndEdges.nodes}
                    key={`${reportId}-issues`}
                  />
                )}
              </div>
            </DidomiTabPanel>
          </DidomiTabPanels>
        </DidomiTabs>
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReportDetail };
