import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { DeleteMemberPropertyNotificationPayload, MemberPropertyNotification } from '@types';

export const useDeleteMemberPropertyNotification = (
  options?: UseMutationOptions<AxiosResponse<MemberPropertyNotification>, AxiosError, DeleteMemberPropertyNotificationPayload>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const deleteEntity = async (payload: DeleteMemberPropertyNotificationPayload) => {
    return await axiosWithInterceptors.delete<MemberPropertyNotification>(`${apiRoutes.memberPropertyNotifications}/${payload.id}`, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation<AxiosResponse<MemberPropertyNotification>, AxiosError, DeleteMemberPropertyNotificationPayload>(deleteEntity, {
    ...options,
    onSuccess: (deletedEntity: AxiosResponse<MemberPropertyNotification>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['member-property-notifications', { organization_id: organizationId }] });

      if (options?.onSuccess) {
        options.onSuccess(deletedEntity, variables, context);
      }
    },
  });
};
