import { Report } from 'types/responses/report';

export const orderReportsByDate = (reports: Report[]) => {
  if (!reports?.length) return [];

  return reports.sort((a, b) => {
    const aDate = a.updated_at || a.created_at;
    const bDate = b.updated_at || b.created_at;

    return new Date(bDate).valueOf() - new Date(aDate).valueOf();
  });
};
