import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose } from '@types';

type UseAllPurposesArgs = {
  enabled?: boolean;
};

/**
 * Fetches the full (max 5000) list of all purposes
 * */
export const useAllPurposes = ({ enabled = true }: UseAllPurposesArgs = {}) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPurposes = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<Purpose>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes?$translations=true&$limit=5000`);

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const query = useQuery<PaginatedResponse<Purpose>, AxiosError>([`purposes`], () => fetchPurposes(), {
    refetchInterval: false,
    enabled,
    staleTime: 0,
    cacheTime: 15_000,
  });

  const fetch = () => queryClient.fetchQuery<PaginatedResponse<Purpose>, AxiosError>([`purposes`], () => fetchPurposes());

  return {
    ...query,
    fetch,
  };
};
