import React from 'react';
import { DidomiModal, DidomiLoader, DidomiModalContent } from '@didomi/ui-atoms-react';

export const LoadingModal = ({ isOpen, isEditing = false }) => {
  return (
    <div>
      <DidomiModal isOpen={isOpen}>
        <DidomiLoader></DidomiLoader>
        <DidomiModalContent className="mt-m">{isEditing ? 'Editing domain ...' : 'Adding domain ...'}</DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
