import React from 'react';
import { DidomiTooltip, DidomiIcon, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { usePropertyReport } from '@hooks';
interface ComplianceIndexCircleProps {
  complianceIndex: string;
  showTooltip?: boolean;
}
const ComplianceIndexCircle = ({ complianceIndex, showTooltip = false }: ComplianceIndexCircleProps) => {
  const colorByIndex = {
    A: '#91C046',
    B: '#7AA7D5',
    C: '#F5C05A',
    D: '#EE805B',
    E: '#B10E00',
  };
  const { isLoading: reportLoading } = usePropertyReport({ reportId: null });

  return (
    <DidomiSkeleton variant="layout" isLoading={reportLoading}>
      <div
        data-skeleton
        style={{
          width: '90px',
          height: '90px',
          boxShadow: 'rgba(0, 0, 0, 0.6) 0px 0px 6px',
          background: colorByIndex[complianceIndex],
          borderColor: '#E2F1F8',
        }}
        className=" relative border-solid border-2 rounded-full"
      >
        {showTooltip && (
          <DidomiTooltip
            className="absolute top-[-15px] right-[-15px]"
            slot="description"
            variant="helper"
            content="The Didomi Rating is an easy way to visualize your website's overall compliance performance"
          >
            <DidomiIcon name="helper-text"></DidomiIcon>
          </DidomiTooltip>
        )}
        {complianceIndex === 'A' && (
          <h1
            style={{ height: '28px', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', letterSpacing: '2px' }}
            className="absolute inset-0	m-auto text-white font-semibold	text-center text-[26px] font-mono flex flex-col justify-center items-center"
          >
            ★★★★
          </h1>
        )}
        {complianceIndex === 'B' && (
          <h1
            style={{ height: '28px', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', letterSpacing: '2px' }}
            className="absolute inset-0	m-auto text-white font-semibold	text-center text-[26px] font-mono flex flex-col justify-center items-center"
          >
            ★★★☆
          </h1>
        )}
        {complianceIndex === 'C' && (
          <h1
            style={{ height: '28px', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', letterSpacing: '2px' }}
            className="absolute inset-0	m-auto text-white font-semibold	text-center text-[26px] font-mono flex flex-col justify-center items-center"
          >
            ★★☆☆
          </h1>
        )}
        {complianceIndex === 'D' && (
          <h1
            style={{ height: '28px', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', letterSpacing: '2px' }}
            className="absolute inset-0	m-auto text-white font-semibold	text-center text-[26px] font-mono flex flex-col justify-center items-center"
          >
            ★☆☆☆
          </h1>
        )}
        {complianceIndex === 'E' && (
          <h1
            style={{ height: '28px', textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', letterSpacing: '2px' }}
            className="absolute inset-0	m-auto text-white font-semibold	text-center text-[26px] font-mono flex flex-col justify-center items-center"
          >
            ☆☆☆☆
          </h1>
        )}
      </div>
    </DidomiSkeleton>
  );
};

export { ComplianceIndexCircle };
