import { useMemo } from 'react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useAllPurposes } from './useAllPurposes.hook';

export const useCustomAndIABPurposes = () => {
  const { organizationId } = useActiveOrganization();
  const { isLoading, data: { data: allPurposes } = {}, error } = useAllPurposes();

  const purposes = useMemo(() => {
    if (!organizationId || !allPurposes?.length) return [];

    return allPurposes.filter(purpose => purpose.organization_id === organizationId || (purpose.organization_id === null && !!purpose.namespaces?.iab2));
  }, [allPurposes, organizationId]);

  return {
    error,
    isLoading,
    data: purposes,
  };
};
