import React, { useMemo, useState } from 'react';
import { DidomiIcon } from '@didomi/ui-atoms-react';
import { Icons } from '@didomi/ui-foundation';
import { tx } from '@twind/core';

interface Props {
  icon: Icons;
  hoverIcon: Icons;
  disabledIcon: Icons;
  disabled?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  children?: React.ReactNode;
  hideOutline?: boolean;
}

export const StateIconButton = ({ icon, disabledIcon, hoverIcon, disabled, onClick, dataTestId = 'state-icon-button', children, hideOutline }: Props) => {
  const [isHover, setIsHover] = useState(false);

  const activeIcon = useMemo(() => {
    if (disabled) {
      return disabledIcon;
    }

    if (isHover) {
      return hoverIcon;
    }

    return icon;
  }, [icon, disabledIcon, hoverIcon, disabled, isHover]);

  return (
    <button
      type="button"
      data-testid={dataTestId}
      className={tx('flex items-center justify-between w-full rounded-full', {
        'focus:(outline outline-[3px] outline-primary-blue-2)': !hideOutline,
        'focus:(outline-none)': hideOutline,
      })}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {children}
      <DidomiIcon style={{ pointerEvents: 'none' }} name={activeIcon} />
    </button>
  );
};
