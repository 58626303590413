import { Cookie, ExemptionCategory, PropertyTrackerItem, Purpose } from '@types';
import { updateCookieWithMatchingPropertySetting } from '@utils';

/**
 * Append exemption status and purposes to the cookies based on property tracker settings
 *
 * @param {Cookie[]} cookies - The list of cookies
 * @param {Purpose[]} purposes - The list of purposes
 * @param {PropertyTrackerItem[]} propertyTrackerSettings - The list of property tracker settings
 * @returns {Cookie[]} - The updated list of trackers with added exemption status and purposes
 */
export const addExemptionStatusAndPurposesToCookies = (
  cookies: Cookie[],
  purposes: Purpose[],
  propertyTrackerSettings: PropertyTrackerItem[],
  exemptionCategories: ExemptionCategory[] = [],
  withOriginalTrackerName: boolean = false,
): Cookie[] => {
  // Prepare a Map for fast lookup of property tracker settings by ID
  const trackerSettingsMap = new Map<string, PropertyTrackerItem>(propertyTrackerSettings.map(propertyTracker => [propertyTracker.id, propertyTracker]));

  // Separate regex-based property tracker settings
  const regexPropertyTrackerSettings = propertyTrackerSettings.filter(item => item.is_regex);

  return cookies.map(cookie => {
    // Update tracker information by matching regex patterns
    const updatedCookie = updateCookieWithMatchingPropertySetting(cookie, purposes, regexPropertyTrackerSettings, withOriginalTrackerName);

    if (updatedCookie) {
      return {
        ...updatedCookie,
        exemptions: updatedCookie?.exemption_category_id ? exemptionCategories.filter(exemptionCategory => exemptionCategory.id === updatedCookie?.exemption_category_id) : [],
      };
    }

    // Find the corresponding property tracker setting by matching the tracker id if no RegEx name found
    const propertyTrackerSetting = trackerSettingsMap.get(cookie.id);
    const selectedPurposes = propertyTrackerSetting ? purposes.filter(purpose => propertyTrackerSetting.purpose_ids?.includes(purpose.id)) : [];

    return {
      ...cookie,
      purposes: selectedPurposes,
      purpose_ids: propertyTrackerSetting?.purpose_ids || [],
      is_exempt: !!propertyTrackerSetting?.exemption_category_id,
      exemption_category_id: propertyTrackerSetting?.exemption_category_id || null,
      exemptions: propertyTrackerSetting?.exemption_category_id
        ? exemptionCategories.filter(exemptionCategory => exemptionCategory.id === propertyTrackerSetting?.exemption_category_id)
        : [],
    };
  });
};
