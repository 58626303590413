import { Partner, PatnerRequestBody } from '@types';
export const generatePatnerRequestBody = (vendor: Partner, selectedVendorDomains, organizationId): PatnerRequestBody => {
  let address = {};
  let contact = {};
  const name = typeof vendor.name === 'string' ? vendor.name : '';
  if (typeof vendor.address === 'string') {
    // If address is a string, convert it into the desired object format
    address = { default: vendor.address };
  } else {
    // If address is already an object, use it as is
    address = vendor.address;
  }

  if (typeof vendor.contact === 'string') {
    // If contact is a string, convert it into the desired object format
    contact = { default: vendor.contact };
  } else {
    // If contact is already an object, use it as is
    contact = vendor.contact;
  }

  const draft = {
    address: address,
    contact: contact,
    spi_purposes_id: vendor.spi_purposes_id || [],
    cookie_max_duration: vendor.cookie_max_duration || null,
    cookie_max_duration_unit: vendor.cookie_max_duration_unit || 'months',
    country_id: vendor.country_id || null,
    default_purposes_id: vendor.default_purposes_id || [],
    legitimate_interest_purposes_id: vendor.legitimate_interest_purposes_id || [],
    links: vendor.links,
    hosts: vendor?.hosts?.length ? [...vendor.hosts, ...selectedVendorDomains] : [...selectedVendorDomains],
    name: name || '',
    organization_id: organizationId,
    protection_id: vendor.protection_id || null,
    category_id: vendor.category_id || 'service-provider',
    taxonomy_id: vendor.taxonomy_id || null,
    uses_non_cookie_access: vendor.uses_non_cookie_access || null,
    last_metadata_partner_event_time: vendor.updated_at,
  };

  return draft;
};

export const handleClickOnMatchFromId = async ({ editVendor, createVendor, redirectBack, queryClient, vendors, id, organizationId, selectedVendorDomains }) => {
  const vendor = vendors.find((vendor: Partner) => vendor.id === id);
  const requestBody = generatePatnerRequestBody(vendor, selectedVendorDomains, organizationId);
  if (vendor && vendor.namespaces === null && vendor.organization_id === organizationId) {
    requestBody.last_metadata_partner_event_type = 'io.didomi.metadata.partner_updated';
    await editVendor({ ...requestBody, id });
    await queryClient.invalidateQueries({ queryKey: ['collected-vendors'] });
    await queryClient.invalidateQueries({ queryKey: ['reports-collected-vendors'] });
    redirectBack();
  } else {
    requestBody.hosts = [...selectedVendorDomains];
    requestBody.notes = `Replica of built-in Didomi vendor ${vendor.name} (${vendor.id})`;
    requestBody.namespaces = null;
    requestBody.last_metadata_partner_event_type = 'io.didomi.metadata.partner_created';
    await createVendor({ ...requestBody });
    await queryClient.invalidateQueries({ queryKey: ['collected-vendors'] });
    await queryClient.invalidateQueries({ queryKey: ['reports-collected-vendors'] });
    redirectBack();
  }
};

export const returnTypeOfVendor = vendor => {
  if (!vendor.organization_id && vendor.namespaces?.iab2 > 0) return 'IAB';
  if (!vendor.organization_id && vendor.namespaces?.google > 0) return 'ATP';
  if (!vendor.organization_id && !vendor.namespaces?.iab2 && !vendor.namespaces?.iab && !vendor.namespaces?.google && !vendor.namespaces?.custom) return 'Model';
  return 'Custom';
};
