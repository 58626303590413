import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReportStatus } from '@enums';
import { Report } from 'types/responses/report';
import { useReports } from './data/reports/useReports.hook';

type ReportInfo = Pick<Report, 'id' | 'status'>;

type UseReportStatusCheckOptions = {
  withStatus: ReportStatus[];
  onReportsChange?: () => void;
};

export type UseReportsStatusCheckReturnType = {
  includeReportsToCheck: (reports: ReportInfo[]) => Report['id'][];
  isCheckingReport: (id: Report['id']) => boolean;
};

/**
 * Custom hook to check the status of reports. If the current report status does not match with one of withStatus prop,
 * a request pull is started until the reports achieve one of the given status.
 * @param {UseReportStatusCheckOptions} options - Options for the hook.
 * @returns {UseReportsStatusCheckReturnType} - An object containing functions to include reports to check and to check if a report is being checked.
 */
export const useReportStatusCheck = ({ withStatus, onReportsChange }: UseReportStatusCheckOptions): UseReportsStatusCheckReturnType => {
  const [reportsToCheck, setReportsToCheck] = useState<Report['id'][]>([]);

  const { data } = useReports({ enabled: reportsToCheck.length > 0, id: reportsToCheck, refetchInterval: 15_000 });

  useEffect(() => {
    if (data?.data?.length) {
      const reportsMatchingStatus = data.data.filter(report => withStatus.includes(report.status as ReportStatus));
      if (reportsMatchingStatus.length) {
        const reportIdsToRemove = reportsMatchingStatus.map(report => report.id);
        setReportsToCheck(currentValue => {
          const remainingReports = currentValue.filter(reportId => !reportIdsToRemove.includes(reportId));
          return remainingReports;
        });
        onReportsChange?.();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, withStatus]);

  /**
   * Checks if a report is being checked.
   * @param {Report['id']} id - The id of the report to check.
   * @returns {boolean} - True if the report is being checked, false otherwise.
   */
  const isCheckingReport = useCallback((id: Report['id']) => reportsToCheck.includes(id), [reportsToCheck]);

  /**
   * Includes reports to the list of reports to check.
   * Only includes the reports that the status does not match { withStatus } prop.
   * @param {ReportInfo[]} reports - The reports to include.
   */
  const includeReportsToCheck = useCallback(
    (reports: ReportInfo[]) => {
      const pendingReports = reports.filter(report => !withStatus.includes(report.status));
      const reportsIdToInclude = pendingReports.map(report => report.id);

      setReportsToCheck(reportsIdToInclude);
      return reportsIdToInclude;
    },
    [withStatus],
  );

  return useMemo(
    () => ({
      isCheckingReport,
      includeReportsToCheck,
    }),
    [isCheckingReport, includeReportsToCheck],
  );
};
