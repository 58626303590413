import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { OrganizationProperty } from '@types';

/**
 * Creates a vendor, makes sure to update the list of vendors with the new added vendor
 * */

export const useEditDomain = (options?: UseMutationOptions<AxiosResponse<OrganizationProperty>, AxiosError, unknown>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async ([payload, id]: [OrganizationProperty, string]) => {
      return axiosWithInterceptors.patch<Partial<OrganizationProperty>>(`${apiRoutes.crProperties}/${id}?organization_id=${organizationId}`, { ...payload, enabled: true });
    },
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.refetchQueries('properties-v2');
        queryClient.invalidateQueries('property-v2');

        options?.onSuccess(...args);
      },
    },
  );
};
