import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  DidomiFiltersBar,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
  DidomiEmptyState,
} from '@didomi/ui-atoms-react';
import { useActiveOrganization } from '@didomi/utility-react';
import { useSessionStorage } from 'usehooks-ts';
import { ComplianceReportMatchDomainToVendorTableRow } from '@components';
import { usePaginatedFilteredItems, useTableState, useVendors, useVendorSuggestions } from '@hooks';
import { Partner } from '@types';
import { aggregatePartnersForMatching } from '@utils';

export type VendorsSortableField = 'nameOrKey' | 'vendor_iabv2_id';

const SEARCH_FIELDS = [{ field: 'name' }];
const vendorTableColHeaderName = [
  { name: 'Vendor name', sortId: 'nameOrKey' },
  { name: 'API ID', sortId: 'id' },
  { name: 'IAB/SDK ID', sortId: 'sdk_id' },
  { name: 'Vendor type', sortId: null },
  { name: 'Purposes (consent)', sortId: null },
  { name: 'Purposes (legitimate interest)', sortId: null },
  { name: 'Action', sortId: null },
];

const ComplianceReportMatchDomainsToVendor = () => {
  const { reportId } = useParams();
  const { organizationId } = useActiveOrganization();

  const [storedUnknownVendorDomains] = useSessionStorage('unknown-vendors-domains-' + reportId, null);
  const storedUnknownVendorsUrlHosts = useMemo(() => {
    return storedUnknownVendorDomains?.map(elm => elm.url_sld);
  }, [storedUnknownVendorDomains]);

  const { data: vendors = [], isLoading } = useVendors();
  const { data: suggestedVendors = [], isLoading: isLoadingSuggestedVendors } = useVendorSuggestions({ urls: storedUnknownVendorsUrlHosts });

  const aggregatedVendorTableData = useMemo(() => {
    if (isLoading || isLoadingSuggestedVendors) return [];
    return aggregatePartnersForMatching(vendors, suggestedVendors, organizationId);
  }, [isLoading, isLoadingSuggestedVendors, vendors, suggestedVendors, organizationId]);

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
    changeSorting,
    sortConfig,
  } = useTableState<VendorsSortableField>({
    defaultLimit: 10,
    defaultFilters: {},
  });
  const { data: filteredVendors, total: vendorsTotal } = usePaginatedFilteredItems<Partner, VendorsSortableField>(
    aggregatedVendorTableData,
    {
      search,
      searchFields: SEARCH_FIELDS,
      limit: limit,
      page: currPage,
      sortConfig,
    },
    'partners',
  );
  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  return (
    <div className="pt-l mb-l">
      <DidomiSkeleton isLoading={isLoading || isLoadingSuggestedVendors} variant="layout">
        <section className="flex justify-between gap-8 mb-4 w-full" data-skeleton>
          <div className="flex gap-2 text-body-small self-center" style={{ alignSelf: 'center!important' }}>
            <div className="text-primary-blue-6 font-semibold">
              {vendorsTotal === aggregatedVendorTableData.length
                ? `${aggregatedVendorTableData.length} vendors`
                : `${vendorsTotal} / ${aggregatedVendorTableData.length} vendors
          `}
            </div>
          </div>
          <DidomiFiltersBar
            data-skeleton
            data-testid="filter-bar-property"
            id="didomi-filters-without-default-value-property"
            defaultValue=""
            filters={{}}
            showSearch
            onSearchTextChange={handleSearchChange}
            placeholderTextFilter={'Search by name'}
            onClearAllFilters={setSearch}
            collapsibleFilters={false}
          ></DidomiFiltersBar>
        </section>

        {filteredVendors?.length ? (
          <>
            <DidomiTable
              sortBy={sortConfig.field}
              sortDirection={sortConfig.dir}
              onSortChange={changeSorting}
              sortable={true}
              minWidth={1250}
              data-skeleton
              style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
            >
              <DidomiTableHeading>
                <DidomiTableHeaderRow>
                  {vendorTableColHeaderName.map((obj, idx) => (
                    <DidomiTableTh key={idx} sortId={obj.sortId} className="text-uppercase">
                      {obj.name.toUpperCase()}
                    </DidomiTableTh>
                  ))}
                </DidomiTableHeaderRow>
              </DidomiTableHeading>
              <DidomiTableBody>
                <ComplianceReportMatchDomainToVendorTableRow vendors={filteredVendors} />
              </DidomiTableBody>
            </DidomiTable>
          </>
        ) : (
          <div data-skeleton className="min-w-[100%]">
            <DidomiEmptyState data-skeleton illustration-name="traces-no-match-found" className="border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1 mb-s">
              <div slot="title">
                <div>
                  <span>No results</span>
                </div>
              </div>
            </DidomiEmptyState>
          </div>
        )}

        <div className="mb-l">
          <DidomiPaginator
            data-skeleton
            data-testid="vendors-paginator"
            className="flex justify-end mt-s"
            page={currPage}
            itemCount={vendorsTotal}
            size={limit}
            onPageSizeChange={setLimit}
            onPageChange={setCurrPage}
          />
        </div>
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReportMatchDomainsToVendor };
