import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { PaginatedResponse, PropertyTrackerItem } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

type UsePropertyTrackerSettingsArgs = {
  propertyId?: string;
  enabled?: boolean;
};

/**
 * Sends request to read property trackers settings data
 */
export const usePropertyTrackerSettings = ({ propertyId, enabled = true }: UsePropertyTrackerSettingsArgs = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPropertyTrackerSettings = async (propertyId: string, limit: number = 1000) => {
    if (propertyId) {
      const response = await axiosWithInterceptors.get<PaginatedResponse<PropertyTrackerItem>>(`${apiRoutes.crProperties}/${propertyId}/trackers`, {
        params: {
          organization_id: organizationId,
          $limit: limit,
        },
      });
      return response.data;
    }
  };

  const query = useQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>([`property-tracker-settings`, propertyId], () => fetchPropertyTrackerSettings(propertyId), {
    refetchInterval: false,
    enabled,
  });

  const fetch = (propertyId: string, limit: number = 1000) =>
    queryClient.fetchQuery<PaginatedResponse<PropertyTrackerItem>, AxiosError>([`property-tracker-settings`, propertyId], () => fetchPropertyTrackerSettings(propertyId, limit));

  return {
    ...query,
    fetch,
  };
};
