import React from 'react';
import { DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';

interface ScenarioTooltipButtonProps {
  onClick?: () => void;
}

export const ScenarioTooltipButton = ({ onClick }: ScenarioTooltipButtonProps) => {
  return (
    <DidomiTooltip content="Delete instruction" className="ml-auto">
      <button
        type="button"
        className="text-neutral-gray-6 rounded-sm outline-offset-1 focus:(outline outline-[3px] outline-primary-blue-2"
        data-testid="delete-action-button"
        onClick={onClick}
      >
        <DidomiIcon name="delete" />
      </button>
    </DidomiTooltip>
  );
};
