import React from 'react';
const ComplianceReportEmptyData = ({ error = false }) => {
  return (
    <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg mt-m">
      {error ? (
        <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg mt-m">
          <img alt="agnostik-logo" className="w-[220px] mt-[140px] mb-xs" src={require('./../../assets/Unexpected-error.png')} />
          <span className="text-center font-semibold text-[18px] text-primary-pink-5 mb-xxxs" data-cy="error-loading">
            Unexpected error
          </span>
          <span className="text-center text-primary-blue-6 text-h3">
            The APIs are not responding, <br />
            Please try again in few minutes.
          </span>
        </div>
      ) : (
        <div className="w-full h-[624px] bg-neutral-gray-1 flex items-center flex-col rounded-lg mt-m">
          <img alt="agnostik-logo" className="w-[220px] mt-[140px] mb-xs" src={require('./../../assets/No-result-found.png')} />
          <span className="text-center text-primary-blue-4 mb-xxxs body-big-semibold">You don’t have any compliance reports yet</span>
          <span className="text-center text-primary-blue-6 text-[20px]">
            You have to add a domain <br /> in order to launch compliance reports.
          </span>
        </div>
      )}
    </div>
  );
};

export { ComplianceReportEmptyData };
