import React, { useState } from 'react';
import { DidomiCheckbox, DidomiChip, DidomiControlledCollapsibleCard, DidomiHeaderInput, DidomiIconButton, DidomiTextInput, DidomiTooltip } from '@didomi/ui-atoms-react';
import { StateIconButton } from '@components';
import { ScenarioActionType } from '@enums';
import { ScenarioAction } from 'interfaces';
import { ScenarioTooltipButton } from '../ScenarioTooltipButton/ScenarioTooltipButton';

interface ScenarioCardProps {
  name: string;
  enabled: boolean;
  availableActions?: ScenarioActionType[];
  selectedActions?: ScenarioAction[];
  disableCheckbox?: boolean;
  showDeleteScenarioButton?: boolean;
  hideDeleteButtonForActions?: ScenarioActionType[];
  editableName?: boolean;
  className?: string;
  onEnabledChange?: (value: boolean) => void;
  onDeleteScenario?: () => void;
  onNameChange?: (value: string) => void;
  onAddAction?: (action: ScenarioActionType) => void;
  onDeleteAction?: (index: number) => void;
  onMoveAction?: (fromIndex: number, toIndex: number) => void;
  onCssSelectorChange?: (index: number, value: string) => void;
  onInputValueChange?: (index: number, value: string) => void;
}

export const ScenarioCard = ({
  name,
  enabled,
  availableActions,
  selectedActions,
  disableCheckbox,
  showDeleteScenarioButton,
  hideDeleteButtonForActions,
  editableName,
  className,
  onEnabledChange,
  onDeleteScenario,
  onNameChange,
  onAddAction,
  onDeleteAction,
  onMoveAction,
  onCssSelectorChange,
  onInputValueChange,
}: ScenarioCardProps) => {
  const [touchedInputIds, setTouchedInputIds] = useState([]);

  const isDefaultActionSelected = selectedActions?.some(action => [ScenarioActionType.ACCEPT, ScenarioActionType.REFUSE].includes(action.type));

  const handleBlur = (actionId: string) => !touchedInputIds.includes(actionId) && setTouchedInputIds([...touchedInputIds, actionId]);

  return (
    <DidomiControlledCollapsibleCard className={`w-[700px] ${className}`}>
      <div slot="header-title-content" className="text-primary-blue-6">
        {!editableName && <h2 className="text-[20px] font-serif font-bold text-primary-blue-6 pb-xxs">{name}</h2>}
        {editableName && <DidomiHeaderInput value={name} icon="edit" tooltipContent="Edit name" onValueChange={ev => onNameChange(ev.detail)} />}
        {enabled && <DidomiChip label="Active" variation="basic" basic-type="success"></DidomiChip>}
        {!enabled && <DidomiChip label="Inactive" variation="basic-outline" basic-type="neutral"></DidomiChip>}
      </div>

      <div slot="header-controls">
        {showDeleteScenarioButton && (
          <DidomiTooltip content="Delete scenario">
            <DidomiIconButton icon="delete" variant="rounded-border" size="small" data-testid="delete-scenario-button" onClick={onDeleteScenario} />
          </DidomiTooltip>
        )}
      </div>

      <div>
        <DidomiTooltip content={disableCheckbox ? 'A maximum of three scenarios can be active simultaneously' : null} placement="top">
          <div className="inline-block">
            <DidomiCheckbox
              label="Enable this scenario"
              disabled={disableCheckbox}
              hideErrorMessage={true}
              checked={enabled}
              onValueChange={e => onEnabledChange(e.detail)}
            ></DidomiCheckbox>
          </div>
        </DidomiTooltip>
        <p className="pl-[31px] text-[14px] mb-s text-primary-blue-6">The bot will run the instructions from the following list.</p>

        <div className="mb-xs">
          {selectedActions?.length > 0 && (
            <>
              <h4 className="text-[16px] font-semibold text-primary-blue-6 mb-xxxs">
                {selectedActions.length} {selectedActions.length === 1 ? 'instruction' : 'instructions'} in the list
              </h4>
              <div className="grid grid-cols-1 gap-xxs">
                {selectedActions.map((action, index) => (
                  <div key={action.id} className="flex">
                    <div className="flex flex-col justify-center gap-xxxs mr-xxs">
                      <StateIconButton
                        icon="move-up"
                        hoverIcon="move-up-hover"
                        disabledIcon="move-up-disabled"
                        disabled={index === 0}
                        dataTestId="action-move-up-button"
                        onClick={() => onMoveAction(index, index - 1)}
                      />
                      <StateIconButton
                        icon="move-down"
                        hoverIcon="move-down-hover"
                        disabledIcon="move-down-disabled"
                        disabled={index === selectedActions.length - 1}
                        dataTestId="action-move-down-button"
                        onClick={() => onMoveAction(index, index + 1)}
                      />
                    </div>

                    <div className="w-full p-xs pb-xxs rounded-md bg-[#F8F9F9]">
                      {
                        {
                          [ScenarioActionType.ACCEPT]: (
                            <div className="flex items-center gap-xxs pb-xxs">
                              <DidomiChip label="Accept" leftIcon="check" variation="basic" basic-type="warning" data-testid="action-accept" />
                              <span className="text-[14px] text-primary-blue-6">The bot will locate the cookie banner and accept all the purposes</span>
                              {!hideDeleteButtonForActions?.includes(ScenarioActionType.ACCEPT) && <ScenarioTooltipButton onClick={() => onDeleteAction(index)} />}
                            </div>
                          ),
                          [ScenarioActionType.REFUSE]: (
                            <div className="flex items-center gap-xxs pb-xxs">
                              <DidomiChip label="Refuse" leftIcon="rejected" variation="basic" basic-type="error" data-testid="action-refuse" />
                              <span className="text-[14px] text-primary-blue-6">The bot will locate the cookie banner and refuse all the purposes</span>
                              {!hideDeleteButtonForActions?.includes(ScenarioActionType.REFUSE) && <ScenarioTooltipButton onClick={() => onDeleteAction(index)} />}
                            </div>
                          ),
                          [ScenarioActionType.CLICK]: (
                            <div>
                              <div className="flex items-center gap-xxs mb-xxs">
                                <DidomiChip label="Click" leftIcon="target" variation="basic" basic-type="default" data-testid="action-click" />
                                <span className="text-[14px] text-primary-blue-6">The bot will click on an element</span>
                                {!hideDeleteButtonForActions?.includes(ScenarioActionType.CLICK) && <ScenarioTooltipButton onClick={() => onDeleteAction(index)} />}
                              </div>
                              <DidomiTextInput
                                value={action.css_selector}
                                placeholder="Add CSS selector here"
                                className="w-full"
                                size="sm"
                                error={touchedInputIds.includes(`css-${action.id}`) && !action.css_selector ? 'CSS selector is required' : null}
                                onValueChange={e => onCssSelectorChange(index, e.detail)}
                                onBlur={() => handleBlur(`css-${action.id}`)}
                              />
                            </div>
                          ),
                          [ScenarioActionType.FILL_IN]: (
                            <div>
                              <div className="flex items-center gap-xxs mb-xxs">
                                <DidomiChip label="Fill in" leftIcon="typography" variation="basic" basic-type="edit-mode" data-testid="action-fill" />
                                <span className="text-[14px] text-primary-blue-6">The bot will enter the following text in an input element</span>
                                {!hideDeleteButtonForActions?.includes(ScenarioActionType.FILL_IN) && <ScenarioTooltipButton onClick={() => onDeleteAction(index)} />}
                              </div>

                              <div className="flex gap-xxs">
                                <DidomiTextInput
                                  value={action.input_value}
                                  placeholder="Add the text here"
                                  className="w-full"
                                  size="sm"
                                  error={touchedInputIds.includes(`text-${action.id}`) && !action.input_value ? 'Text is required' : null}
                                  onValueChange={e => onInputValueChange(index, e.detail)}
                                  onBlur={() => handleBlur(`text-${action.id}`)}
                                />
                                <DidomiTextInput
                                  value={action.css_selector}
                                  placeholder="Add CSS selector here"
                                  className="w-full"
                                  size="sm"
                                  error={touchedInputIds.includes(`css-${action.id}`) && !action.css_selector ? 'CSS selector is required' : null}
                                  onValueChange={e => onCssSelectorChange(index, e.detail)}
                                  onBlur={() => handleBlur(`css-${action.id}`)}
                                />
                              </div>
                            </div>
                          ),
                        }[action.type]
                      }
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {(!selectedActions || selectedActions?.length === 0) && (
            <>
              <h4 className="text-[16px] font-semibold text-primary-blue-6 mb-xxxs">No instruction in the list</h4>
              <div className="border border-dotted border-neutral-gray-5 rounded-md p-xs text-[14px] text-neutral-gray-6 ">
                The bot will run the analysis without taking any actions.
              </div>
            </>
          )}
        </div>

        {availableActions?.length > 0 && (
          <>
            <h4 className="text-[16px] font-semibold text-primary-blue-6 mb-xxxs">Add an instruction</h4>
            <div className="flex gap-xxs">
              {availableActions.includes(ScenarioActionType.ACCEPT) && (
                <DidomiChip
                  label="Accept"
                  leftIcon="check"
                  variation={isDefaultActionSelected ? 'basic-outline' : 'basic'}
                  basic-type={isDefaultActionSelected ? 'empty' : 'warning'}
                  data-testid="available-action-accept"
                  onClick={() => !isDefaultActionSelected && onAddAction(ScenarioActionType.ACCEPT)}
                />
              )}
              {availableActions.includes(ScenarioActionType.REFUSE) && (
                <DidomiChip
                  label="Refuse"
                  leftIcon="rejected"
                  variation={isDefaultActionSelected ? 'basic-outline' : 'basic'}
                  basic-type={isDefaultActionSelected ? 'empty' : 'error'}
                  data-testid="available-action-refuse"
                  onClick={() => !isDefaultActionSelected && onAddAction(ScenarioActionType.REFUSE)}
                />
              )}
              {availableActions.includes(ScenarioActionType.CLICK) && (
                <DidomiChip
                  label="Click"
                  leftIcon="target"
                  variation="basic"
                  basic-type="default"
                  data-testid="available-action-click"
                  onClick={() => onAddAction(ScenarioActionType.CLICK)}
                />
              )}
              {availableActions.includes(ScenarioActionType.FILL_IN) && (
                <DidomiChip
                  label="Fill in"
                  leftIcon="typography"
                  variation="basic"
                  basic-type="edit-mode"
                  data-testid="available-action-fill"
                  onClick={() => onAddAction(ScenarioActionType.FILL_IN)}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DidomiControlledCollapsibleCard>
  );
};
