export enum TrackerLifetime {
  SESSION_OR_UNKNOWN = 'session / unknown',
}

export const getTrackerAgeInMonthOrDays = (seconds): string => {
  if (seconds < 0) return TrackerLifetime.SESSION_OR_UNKNOWN;
  let days = Math.round(parseInt(seconds) / 86400);
  if (typeof days !== 'number') return '';
  if (days > -31 && days < 31) return days + ' ' + 'days';
  const month = Math.round(days / 30.5);
  return month + ' ' + 'months';
};

export const getTrackerAgeInMonthsNumeric = (seconds): number => {
  if (seconds < 0) return 0;
  let days = Math.round(parseInt(seconds) / 86400);
  return Math.round(days / 30.5);
};
