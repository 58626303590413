import jsPDF, { jsPDFOptions } from 'jspdf';
// eslint-disable-next-line import/no-named-as-default
import autoTable, { UserOptions } from 'jspdf-autotable';
import Papa from 'papaparse';
import writeXlsxFile from 'write-excel-file';

/**
 * Exports the given rows of data as a PDF file using jsPDF and jsPDF-AutoTable.
 * @param {Record<string, string>[]} rows - An array of records, where each record represents a row of data with key-value pairs.
 * @param {string} fileName - The base name for the generated PDF file (without the .pdf extension).
 * @param {jsPDFOptions} pdfOptions - Optional. Configuration options for jsPDF.
 * @param {Partial<UserOptions>} customConfigOverride - Optional. Custom configuration overrides for jsPDF-AutoTable.
 */
export const exportPDF = (rows: Record<string, string>[], fileName: string, pdfOptions: jsPDFOptions = {}, customConfigOverride: Partial<UserOptions> = {}) => {
  const doc = new jsPDF(pdfOptions);
  const head = [Object.keys(rows[0])];
  const body = rows.map(row => head[0].map(key => row[key]));
  const columns = head[0].map(key => ({ header: key, dataKey: key }));
  autoTable(doc, {
    head,
    body,
    columns,
    ...customConfigOverride,
  });
  doc.save(`${fileName}.pdf`);
};

/**
 * Asynchronously exports the given rows of data as an Excel file.
 * @param {Record<string, string>[]} rows - An array of records, where each record represents a row of data with key-value pairs.
 * @param {string} fileName - The base name for the generated Excel file (without the .xlsx extension).
 * @returns {Promise<void>} A promise that resolves when the Excel file is successfully created and saved.
 */
export const exportExcel = async (rows: Record<string, string>[], fileName: string) => {
  const header = Object.keys(rows[0]).map(columnName => ({ value: columnName }));
  const excelRows = rows.map(row => {
    return Object.keys(row).map(columnName => ({
      value: row[columnName],
    }));
  });

  await writeXlsxFile([header, ...excelRows], {
    fileName: `${fileName}.xlsx`,
  });
};

/**
 * Exports the given rows of data as a CSV file.
 * @param {Record<string, string>[]} rows - An array of records, where each record represents a row of data with key-value pairs.
 * @param {string} fileName - The base name for the generated CSV file (without the .csv extension).
 */
export const exportCSV = (rows: Record<string, string>[], fileName: string) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Exports the given content of data as a JSON file.
 *
 * @param {Record<string, unknown>[]} content - An array of records, where each record represents a row of data with key-value pairs.
 * @param {string} fileName - The base name for the generated JSON file (without the .json extension).
 */
export const exportJSON = (content: Record<string, unknown>[], fileName: string) => {
  const json = JSON.stringify(content, null, 2);
  const blob = new Blob([json], { type: 'application/json;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.json`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
