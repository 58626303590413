import { isValid, parse } from 'psl';

export const destructUrls = (urls: string[]): string[] => {
  const result: string[] = [];
  urls.forEach(url => {
    let host = url;

    while (host) {
      result.push(host);
      host = getNextClosestHost(host);
    }
  });
  return result;
};

export const getNextClosestHost = (host: string) => {
  // parse the host and extract the subdomain and the SLD
  // also extracts listed (boolean) if the TLD is supported
  // official doc : https://publicsuffix.org/
  // implementation : https://github.com/lupomontero/psl#readme

  // check if the public suffix is valid
  if (!isValid(host)) {
    return null;
  }

  const parsedDomain: any = parse(host);

  // if tld not listed then exit.
  if (!parsedDomain.listed) {
    return null;
  }

  // init components to build the CCSLD
  let components = [parsedDomain.sld, 'CCSLD'];

  // if any subdomain (ex: 'privacy-center' in sdk.privacy-center.org)
  // add it to the head of the components array
  if (parsedDomain.subdomain) {
    components = [parsedDomain.subdomain, ...components];
  }
  // build the ccsld
  const ccsld = components.join('.');
  // compute the current host level
  const level = ccsld.split('.').length - 1;

  // if reached the SLD (ex: privacy-center.org)
  // then send the stop signal, no more iteration.
  if (level <= 1) {
    return null;
  }

  // otherwise, get the very next closest host
  // ex: sdk.privacy-center.org => privacy-center.org
  // return the very next closest host
  return host
    .split('.')
    .slice(-level + (ccsld.split('.').length - 1) - (host.split('.').length - 1), host.length)
    .join('.');
};
