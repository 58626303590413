import React, { useState } from 'react';
import { DidomiAutocompleteCustomEvent } from '@didomi/ui-atoms';
import { DidomiModal, DidomiModalHeader, DidomiModalContent, DidomiButton, DidomiSelectOptions, DidomiSelectOption, DidomiAutocomplete } from '@didomi/ui-atoms-react';
import { Property } from 'types/responses/property';

interface CloneTrackerSettingsModalProps {
  isOpen: boolean;
  properties: Property[];
  selectedPropertyIds: string[];
  onOptionSelect: (value: string[]) => void;
  onClose: (value: boolean) => void;
  onSubmit: (selectedPropertyIds: string[]) => void;
}

export const CloneTrackerSettingsModal = ({ isOpen, properties, selectedPropertyIds, onOptionSelect, onClose, onSubmit }: CloneTrackerSettingsModalProps) => {
  const [filteredProperties, setFilteredProperties] = useState<Property[]>(properties);

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      onClose(e.detail);
    }
  };

  const handleSearchChange = async (e: CustomEvent<string>) => {
    const searchQuery = e.detail.toLowerCase();
    if (!searchQuery) {
      setFilteredProperties(properties);
    }
    const filteredProperties = properties.filter(property => property.name.toLowerCase().includes(searchQuery));
    setFilteredProperties(filteredProperties);
  };

  const isSubmitDisabled = selectedPropertyIds.length === 0;

  return (
    <div>
      <DidomiModal id="modal" variant="slim" isOpen={isOpen} onOpenChange={handleDialogChange} data-testid="ValidationCloneTrackerSettingsModal" closable>
        <DidomiModalHeader
          modal-sub-title="Apply to other properties"
          modal-description="You want to apply the same settings of Purposes and Exemption status to other properties. Select the relevant properties."
        ></DidomiModalHeader>
        <DidomiModalContent>
          {properties && (
            <>
              <DidomiAutocomplete
                multi={true}
                data-testid="properties-autocomplete"
                label="Properties"
                placeholder={'Select properties'}
                name="select-properties"
                className="mt-4"
                data-skeleton
                value={selectedPropertyIds}
                onValueChange={(e: DidomiAutocompleteCustomEvent<string[]>) => onOptionSelect(e.detail)}
                onSearchChange={handleSearchChange}
                onAutocompleteClear={() => onOptionSelect([])}
                size="md"
              >
                <DidomiSelectOptions>
                  {filteredProperties &&
                    filteredProperties?.map((property: Property) => (
                      <DidomiSelectOption label={property.name} value={property.id} key={property.id} selectable={true} selected={selectedPropertyIds.includes(property.id)} />
                    ))}
                </DidomiSelectOptions>
              </DidomiAutocomplete>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <DidomiButton size="medium" variant="secondary" style={{ marginBottom: '10px' }} full-width onClick={() => onClose(false)}>
                  Cancel
                </DidomiButton>
                <DidomiButton size="medium" variant="main" full-width disabled={isSubmitDisabled} onClick={() => !isSubmitDisabled && onSubmit(selectedPropertyIds)}>
                  Confirm
                </DidomiButton>
              </div>
            </>
          )}
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
