import { Cookie, PropertyTrackerItem, Purpose } from '@types';
import { updateCookieWithMatchingPropertySetting, generatePropertyTrackerId } from '@utils';

/**
 * Generates IDs for each tracker in the provided list based on the property ID.
 *
 * @param {string} propertyId - The property ID.
 * @param {Cookie[]} trackers - The list of trackers for which to generate IDs.
 * @returns {Cookie[]} The list of trackers with generated IDs.
 */
export const assignCookieIds = (propertyId: string, trackers: Cookie[]): Cookie[] => {
  return trackers.map(tracker => {
    const trackerId = generatePropertyTrackerId({
      property_id: propertyId,
      name: tracker.name,
      is_third_party: tracker.is_third_party,
      initiator_vendor_id: tracker.initiator_vendor_id,
      initiator_url_host: tracker.initiator_url_host,
      initiator_url_sld: tracker.initiator_url_sld,
      type: tracker.type,
    });

    return {
      ...tracker,
      id: trackerId,
    };
  });
};

/**
 * Checks if a tracker matches any of the regex trackers.
 *
 * @param {PropertyTrackerItem} tracker - The tracker to check.
 * @param {PropertyTrackerItem[]} regexTrackers - The list of regex trackers.
 * @returns {boolean} - True if the tracker matches any regex tracker, otherwise false.
 */
export const matchesRegexTracker = (tracker: PropertyTrackerItem, regexTrackers: PropertyTrackerItem[]): boolean => {
  return regexTrackers.some(regexTracker => {
    try {
      const regexPattern = new RegExp(regexTracker.name, 'ui');
      return (
        regexPattern.test(tracker.name) &&
        tracker.type === regexTracker.type &&
        tracker.is_third_party === regexTracker.is_third_party &&
        tracker.initiator_vendor_id === regexTracker.initiator_vendor_id
      );
    } catch {
      return false;
    }
  });
};

/**
 * Updates a tracker with purposes and exemption details.
 *
 * @param {PropertyTrackerItem} tracker - The tracker to update.
 * @param {Purpose[]} purposes - The list of purposes to filter by.
 * @returns {Cookie} - The updated tracker with purposes and exemption details.
 */
export const updateTrackerDetails = (tracker: PropertyTrackerItem, purposes: Purpose[]): Cookie => {
  return {
    ...tracker,
    purposes: purposes.filter(purpose => tracker.purpose_ids?.includes(purpose.id)),
    is_exempt: !!tracker.exemption_category_id,
    exemption_category_id: tracker.exemption_category_id,
    purpose_ids: tracker.purpose_ids,
  } as Cookie;
};

/**
 * Overwrite report trackers with property tracker settings purposes and exemption status if id matched and then append the non-matched trackers into the list
 *
 * @param {String} propertyId - The Id of the property
 * @param {Cookie[]} cookies - The list of aggregated trackers from the report
 * @param {PropertyTrackerItem[]} propertyTrackerSettings - The list of property tracker settings
 * @param {Purpose[]} purposes - The list of purposes
 * @returns {Cookie[]} - The merged list of aggregated trackers
 */
export const applyPropertyTrackerSettingsToCookies = (propertyId: string, cookies: Cookie[], purposes: Purpose[], propertyTrackerSettings: PropertyTrackerItem[]): Cookie[] => {
  const nonRegexPropertyTrackerSettings = propertyTrackerSettings.filter(item => !item.is_regex);
  const regexPropertyTrackerSettings = propertyTrackerSettings.filter(item => item.is_regex);

  // No cookies exist on report to apply tracker settings, so just return existing property tracker settings
  if (!cookies.length && propertyTrackerSettings.length) {
    const regexPropertyTrackers = regexPropertyTrackerSettings
      .filter(regexTracker => {
        try {
          new RegExp(regexTracker.name, 'ui');
        } catch {
          return false;
        }

        return true;
      })
      .map(regexTracker => updateTrackerDetails(regexTracker, purposes));

    const nonRegexPropertyTrackers = nonRegexPropertyTrackerSettings
      .filter(customTracker => !matchesRegexTracker(customTracker, regexPropertyTrackerSettings))
      .map(tracker => updateTrackerDetails(tracker, purposes));

    return [...regexPropertyTrackers, ...nonRegexPropertyTrackers];
  }

  // Prepare a Map from property tracker settings list to lookup
  const trackerSettingsMap = new Map<string, PropertyTrackerItem>(propertyTrackerSettings.map(propertyTracker => [propertyTracker.id, propertyTracker]));

  // Assign Ids to all the trackers
  const cookiesWithIds = assignCookieIds(propertyId, cookies);

  // Prepare a Map from report's tracker list
  const reportCookiesMap = new Map<string, Cookie>(
    cookiesWithIds.map(item => {
      // Find the RegEx pattern name matched property tracker settings, if not use the original tracker
      const cookie = updateCookieWithMatchingPropertySetting(item, purposes, regexPropertyTrackerSettings) || item;
      return [cookie.id, cookie];
    }),
  );

  // Look for matching trackers by compare tracker Id which already exists in property tracker settings
  const mergedCookieIds = [];
  reportCookiesMap.forEach((tracker: Cookie) => {
    if (trackerSettingsMap.has(tracker.id)) {
      const propertyTrackerSetting = trackerSettingsMap.get(tracker.id);
      const selectedPurposes = purposes.filter(purpose => propertyTrackerSetting.purpose_ids?.includes(purpose.id));

      mergedCookieIds.push(tracker.id);

      reportCookiesMap.set(tracker.id, {
        ...tracker,
        purposes: selectedPurposes,
        purpose_ids: propertyTrackerSetting.purpose_ids,
        is_exempt: !!propertyTrackerSetting.exemption_category_id,
        exemption_category_id: propertyTrackerSetting.exemption_category_id,
      });
    }
  });

  // Finally append non-existing property tracker settings into the list
  nonRegexPropertyTrackerSettings
    .filter(customTracker => !mergedCookieIds.includes(customTracker.id) && !matchesRegexTracker(customTracker, regexPropertyTrackerSettings))
    .map(tracker => {
      const missingTracker = updateTrackerDetails(tracker, purposes);
      reportCookiesMap.set(missingTracker.id, missingTracker);
    });

  return Array.from(reportCookiesMap.values());
};
