import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ExemptionCategory } from '@types';

type UseTrackerExemptionCategoriesArgs = {
  enabled?: boolean;
};

/**
 * Hooks to read exemption categories from the server
 */
export const useTrackerExemptionCategories = ({ enabled = true }: UseTrackerExemptionCategoriesArgs = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchTrackerExemptioncategories = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<ExemptionCategory>>(apiRoutes.exemptionCategories, {
        params: {
          organization_id: organizationId,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const query = useQuery<PaginatedResponse<ExemptionCategory>, AxiosError>([`exemption-categories`], () => fetchTrackerExemptioncategories(), {
    refetchInterval: false,
    enabled,
  });

  const fetch = () => queryClient.fetchQuery<PaginatedResponse<ExemptionCategory>, AxiosError>([`exemption-categories`], () => fetchTrackerExemptioncategories());

  return {
    ...query,
    fetch,
  };
};
