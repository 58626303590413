import React, { useEffect, useMemo, useState } from 'react';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiSkeleton, DidomiPaginator, DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { ComplianceReportCard, ComplianceReportEmptyData } from '@components';
import { PropertyWithOptions, ReportStatus } from '@enums';
import { useTableState, usePaginatedFilteredItems, useAccessPolicies, useReportStatusCheck } from '@hooks';
import { SortConfig } from '@types';
import { filterArrayByComplianceIndex, orderReportsByDate } from '@utils';
import { useProperties } from '../../hooks/data/properties/useProperties.hook';
import { Property } from '../../types/responses/property';

const SEARCH_FIELDS = [{ field: 'website' }, { field: 'name' }];
const filter = {
  index: {
    placeholder: 'Didomi Rating',
    value: '',
    multi: true,
    options: [
      { value: 'A', label: '★★★★' },
      { value: 'B', label: '★★★☆' },
      { value: 'C', label: '★★☆☆' },
      { value: 'D', label: '★☆☆☆' },
      { value: 'E', label: '☆☆☆☆' },
    ],
  },
};

const FINAL_REPORT_STATES = [ReportStatus.ANALYZED, ReportStatus.FAILURE];

const SORT: SortConfig<keyof Property> = {
  field: 'created_at',
  dir: 'asc',
};

const ComplianceReports = () => {
  const { hasComplianceReportEditAccess, isLoading: isLoadingAccessPolicies } = useAccessPolicies();

  const [selectedIndexFilter, setSelectedIndexFilter] = useState([]) as any;
  const {
    data: { data: listProperties } = {},
    isLoading: isLoadingProperties,
    error,
    refetch,
  } = useProperties({
    limit: 1000,
    with: [PropertyWithOptions.LATEST_REPORTS],
    sort: SORT,
  });

  const { includeReportsToCheck } = useReportStatusCheck({
    withStatus: FINAL_REPORT_STATES,
    onReportsChange: () => {
      refetch();
    },
  });

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });
  const [sortConfig] = useState<SortConfig<any>>({ field: 'website', dir: 'asc' });

  const filters = useMemo(() => {
    return [{ filter: filterArrayByComplianceIndex, value: selectedIndexFilter }];
  }, [selectedIndexFilter]);

  const { data: displayProperties, total: paginationTotal } = usePaginatedFilteredItems(
    listProperties,
    {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      filters,
      sortConfig,
    },
    'homepage',
  );

  useEffect(() => {
    if (displayProperties.length) {
      const reports = displayProperties
        .map(property => {
          const orderedReports = orderReportsByDate(property.latest_reports || []);
          return orderedReports[0];
        })
        .filter(Boolean);

      includeReportsToCheck(reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayProperties]);

  const isLoading = isLoadingProperties || isLoadingAccessPolicies;

  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>) => {
    const value = newFilterChange.detail.newValue;
    const key = newFilterChange.detail.key;
    if (key === 'index') {
      setSelectedIndexFilter(value);
    }
  };

  const handleFiltersReset = () => {
    setSelectedIndexFilter('');
    setSearch('');
  };

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  return (
    <div className="pb-unsafe-bottom-space">
      <DidomiSkeleton isLoading={isLoading} className="h-[80vh] w-full">
        {!isLoading &&
          (listProperties?.length ? (
            <div>
              <div className="mt-xs">
                <DidomiFiltersBar
                  data-skeleton
                  filters={filter || {}}
                  data-testid="filter-bar-score"
                  defaultValue="website"
                  id="didomi-filters-without-default-value"
                  placeholderTextFilter={'Search'}
                  left-text={
                    paginationTotal === listProperties?.length ? `${listProperties?.length || 0} domains` : `${paginationTotal || 0} / ${listProperties?.length || 0} domains`
                  }
                  showSearch={true}
                  collapsible-filters={true}
                  onSearchTextChange={handleSearchChange}
                  onFilterValueChange={handleFilterChange}
                  onClearAllFilters={handleFiltersReset}
                ></DidomiFiltersBar>

                {displayProperties.map((property, key) => {
                  return (
                    <div key={`website-${property.id}`} data-skeleton>
                      <ComplianceReportCard
                        hasComplianceReportEditAccess={hasComplianceReportEditAccess}
                        menuKey={key}
                        property={property}
                        onPropertyDeleted={() => refetch()}
                        onReportCreated={() => refetch()}
                      />
                    </div>
                  );
                })}
              </div>
              <DidomiPaginator
                data-skeleton
                className="self-end mb-xs w-full justify-end flex mb-[150px]"
                variant="normal"
                page={currPage}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={setLimit}
                onPageChange={setCurrPage}
              />
            </div>
          ) : (
            <ComplianceReportEmptyData error={!!error} />
          ))}
      </DidomiSkeleton>
    </div>
  );
};

export { ComplianceReports };
