import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut, useAllPurposes } from '@hooks';
import { PaginatedResponse, PatnerRequestBody, Partner } from '@types';
import { mapVendorResult } from '@utils';

/**
 * Edits a vendor, makes sure to update the list of vendors with the edited vendor
 * */
export const useEditVendor = (options?: UseMutationOptions<AxiosResponse<Partner>, AxiosError, PatnerRequestBody & { id: string }>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  const { data: purposes } = useAllPurposes();

  return useMutation(
    async ({ id, ...vendor }: PatnerRequestBody & { id: string }) => {
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}metadata/partners/${id}?$translations=true`, vendor, {});
    },
    {
      ...options,
      onSuccess: (editedVendor: AxiosResponse<Partner>, variables, context) => {
        const editedVendorMapped = mapVendorResult(editedVendor.data, purposes?.data);

        const vendorsGet: PaginatedResponse<Partner> = queryClient.getQueryData(['vendors', organizationId]);
        /* istanbul ignore if */
        if (vendorsGet) {
          queryClient.setQueryData(['vendors', organizationId], {
            ...vendorsGet,
            data: vendorsGet.data.map(vendor => (vendor.id === variables.id ? editedVendorMapped : vendor)),
            total: vendorsGet.total + 1,
          });
        }

        const vendorGet: PaginatedResponse<Partner> = queryClient.getQueryData(['vendor', variables.id]);

        /* istanbul ignore if */
        if (vendorGet) {
          queryClient.setQueryData(['vendor', variables.id], editedVendorMapped);
        }

        if (options?.onSuccess) {
          options.onSuccess(editedVendor, variables, context);
        }
        return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners/${editedVendorMapped.id}?organization_id=${organizationId}`, {
          hosts: editedVendorMapped.hosts,
        });
      },
    },
  );
};
