import React from 'react';
import { ComplianceReportUnmatchedVendorDomains } from '@components';

interface UnmatchVendorDomainProps {
  selectedVendorHosts?: any[];
  setSelectedVendorHosts?: Function;
}
/**
 * UnmatchVendorDomain Page
 */
export const UnmatchVendorDomain = ({ selectedVendorHosts, setSelectedVendorHosts }: UnmatchVendorDomainProps): JSX.Element => {
  return (
    <main className="w-full h-auto">
      <ComplianceReportUnmatchedVendorDomains selectedVendorHosts={selectedVendorHosts} setSelectedVendorHosts={setSelectedVendorHosts} />
    </main>
  );
};
