import { Cookie, PropertyTrackerItem, Purpose } from '@types';
import { getInitiatorVendorIdentifier } from '@utils';

/**
 * Updates the tracker with the matching property tracker setting if a pattern match is found
 *
 * @param {Cookie} cookie - The tracker cookie to be matched and updated
 * @param {Purpose[]} purposes - The list of purposes for which the tracker might be used
 * @param {PropertyTrackerItem[]} propertyTrackerSettings - The settings containing regex patterns and other attributes to match against the tracker
 * @returns {Cookie | null} The updated tracker if a match is found, otherwise the original tracker
 */
export const updateCookieWithMatchingPropertySetting = (
  cookie: Cookie,
  purposes: Purpose[],
  propertyTrackerSettings: PropertyTrackerItem[],
  withOriginalTrackerName: boolean = false,
): Cookie | null => {
  for (const propertyTrackerSetting of propertyTrackerSettings) {
    try {
      // Prepare the regex pattern from name
      const regexPattern = new RegExp(propertyTrackerSetting.name, 'ui');

      // Check if RegEx name pattern match with the other attributes
      // If so then update the name of the tracker by the regex pattern and add purposes with exemption status
      if (
        regexPattern.test(cookie.name) &&
        cookie.type === propertyTrackerSetting.type &&
        cookie.is_third_party === propertyTrackerSetting.is_third_party &&
        getInitiatorVendorIdentifier(cookie) === propertyTrackerSetting.initiator_vendor_id
      ) {
        const selectedPurposes = purposes.filter(purpose => propertyTrackerSetting.purpose_ids?.includes(purpose.id));
        return {
          ...cookie,
          id: propertyTrackerSetting.id,
          name: withOriginalTrackerName ? cookie.name : propertyTrackerSetting.name,
          is_regex: true,
          purposes: selectedPurposes,
          purpose_ids: propertyTrackerSetting.purpose_ids,
          is_exempt: !!propertyTrackerSetting.exemption_category_id,
          exemption_category_id: propertyTrackerSetting.exemption_category_id,
        };
      }
    } catch (error) {
      return null;
    }
  }

  return null;
};
