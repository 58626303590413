import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Report } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

type UsePropertyReportArgs = {
  reportId?: string | number;
  enabled?: boolean;
};

export const usePropertyReport = ({ reportId, enabled = true }: UsePropertyReportArgs) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const fetchPropertyReportByReportId = async reportId => {
    if (reportId) {
      const response = await axiosWithInterceptors.get<Report>(`${CONFIG.environmentConfig.apiBaseUrl}agnostik/reports/compliances/${reportId}`, {
        params: {
          organization_id: organizationId,
        },
      });
      return response.data;
    }
  };

  const results = useQuery<Report, AxiosError>([`report`, reportId], () => fetchPropertyReportByReportId(reportId), {
    refetchInterval: false,
    enabled,
  });

  const fetch = (reportId: string) => queryClient.fetchQuery<Report, AxiosError>([`report`, reportId], () => fetchPropertyReportByReportId(reportId));

  return {
    ...results,
    fetch,
  };
};
