import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse } from '@types';
// TODO: change path to @interfaces
import { Member } from '../../../interfaces';

const ENTITIES_FETCH_LIMIT = 1000;

export const useMembers = (options?: UseQueryOptions<PaginatedResponse<Member>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchMembers = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Member>>(apiRoutes.members, {
      params: { organization_id: organizationId, $limit: ENTITIES_FETCH_LIMIT },
    });
    return response.data;
  };

  return useQuery<PaginatedResponse<Member>, AxiosError>(['members', { organization_id: organizationId }], fetchMembers, options);
};
