/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { DidomiCardContainer, DidomiSelect, DidomiSelectOption, DidomiSelectOptions, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useWorker } from '@shopify/react-web-worker';
import { createWorkerFactory } from '@shopify/web-worker';
import { DataSet } from 'vis-data/peer/esm/vis-data';
import { Network } from 'vis-network';
import { SCENARIOS } from '@constants';
import { InitiatorDetailModal } from '@modals';
import { Report, Scenario } from '@types';

const createWorker = createWorkerFactory(() => import('../../utils/nodesAndEdgesFromRequestsAndTrackers'));

interface Props {
  report: Report;
} /* istanbul ignore file */

const filterSucceededScenarioAndExtractKey = (scenarios: Scenario[]) => {
  if (!scenarios) return [];

  return scenarios.filter(scenario => scenario.status === 'SUCCEEDED').map(scenario => scenario.key);
};

const ComplianceReportVendorGraph = ({ report }: Props) => {
  const { nodesAndEdgesFromRequestsAndTrackers } = useWorker(createWorker);
  const nodeAndEdgeCache = useRef<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [clickedVendorNodeId, setClickedVendorNodeId] = useState(null);
  const [network, setNetwork] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState<Scenario['key']>('CONSENT_TO_ALL');
  const [availableScenarios, setAvailableScenarios] = useState<Scenario['key'][]>([]);

  /* istanbul ignore next */
  const triggerModalBySelectingVendor = vendorId => {
    setClickedVendorNodeId(vendorId);
    setIsOpen(true);
  };
  /* istanbul ignore next */
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleVendorChange = (id: string) => {
    setClickedVendorNodeId(id);
    network.focus(id, { animation: true });
    network.selectNodes([id]);
  };

  /* DATASET */

  const nodesDataSet = new DataSet([]);
  const edgesDataSet = new DataSet([]);
  const data = {
    nodes: nodesDataSet,
    edges: edgesDataSet,
  };
  const options = {
    interaction: {
      selectConnectedEdges: false,
    },
    nodes: {
      //chosen: false,
      shape: 'dot',
      size: 2,
      color: '#D1EFFA',
      font: {
        face: '"IBM Plex Sans", Helvetica, Arial',
        size: 24,
        color: '#666',
      },
      borderWidth: 0.5,
      borderWidthSelected: 4,
    },
    edges: {
      dashes: false,
      selectionWidth: 4,
    },
    physics: {
      forceAtlas2Based: {
        gravitationalConstant: -26,
        centralGravity: 0.005,
        springLength: 230,
        springConstant: 0.18,
      },
      maxVelocity: 146,
      solver: 'forceAtlas2Based',
      timestep: 0.35,
      stabilization: {
        enabled: true,
        iterations: 1500,
        updateInterval: 25,
      },
    },
    layout: {
      randomSeed: 34,
    },
  };

  const processAvailableScenarios = (report: Report) => {
    const requestScenarios = report.details_json.requests.map(request => filterSucceededScenarioAndExtractKey(request.scenarios)).flat();
    const cookiesScenarios = report.details_json.property.cookies.map(cookie => filterSucceededScenarioAndExtractKey(cookie.scenarios)).flat();
    const scenarios = new Set([...requestScenarios, ...cookiesScenarios]);
    const sortedScenarios = Array.from(scenarios).sort((a, b) => a.localeCompare(b));
    setAvailableScenarios(sortedScenarios as Scenario['key'][]);
  };

  useEffect(() => {
    if (report && report.details_json.vendors) {
      processAvailableScenarios(report);
    }
  }, [report]);

  useEffect(() => {
    if (!report?.details_json?.vendors) return;
    const container: HTMLElement = document.getElementById('graphId') as HTMLElement;
    if (!container) return;

    const processReport = async () => {
      // Cleaning the cache if the report changes
      if (nodeAndEdgeCache.current.reportId !== report.id) {
        nodeAndEdgeCache.current = { reportId: report.id };
      }

      setIsLoading(true);
      setError(false);
      try {
        const network = new Network(container, data, options);
        setNetwork(network);
        let lastSelectedNodeId;
        network.on('click', ev => {
          if (!ev?.nodes?.length) {
            return;
          }

          // open the vendor preview modal when we click again on the highlighted node
          if (ev?.nodes?.[0] === lastSelectedNodeId) {
            triggerModalBySelectingVendor(ev?.nodes[0]);
            network.unselectAll();
            lastSelectedNodeId = null;
            return;
          }

          ev?.nodes?.[0] && network.focus(ev?.nodes[0]);
          lastSelectedNodeId = ev?.nodes?.[0];
          network.unselectAll();
          network.selectNodes([lastSelectedNodeId], true);
        });

        if (!nodeAndEdgeCache.current[selectedScenario]) {
          const filteredRequestsByScenario = report.details_json.requests.filter(rq => {
            return rq?.scenarios?.length ? rq.scenarios.some(scenario => scenario.key === selectedScenario && scenario.status === 'SUCCEEDED') : true;
          });

          const filteredTrackersByScenario = report?.details_json?.property.cookies.filter(ck => {
            return ck?.scenarios?.length ? ck.scenarios.some(scenario => scenario.key === selectedScenario && scenario.status === 'SUCCEEDED') : true;
          });

          nodeAndEdgeCache.current[selectedScenario] = await nodesAndEdgesFromRequestsAndTrackers(filteredRequestsByScenario, filteredTrackersByScenario);
        }

        const { nodes: filteredNodes, edges: filteredEdges } = nodeAndEdgeCache.current[selectedScenario];

        const nodes = Object.values(filteredNodes);
        nodesDataSet.clear();
        edgesDataSet.clear();
        nodesDataSet.add(nodes);
        edgesDataSet.add(Object.values(filteredEdges));
        const masterNode = Object.values(filteredNodes).find(({ host_is_property }: Record<string, string>) => host_is_property) as Record<string, string> | undefined;

        if (masterNode) {
          network.once('stabilized', () => {
            network.focus(masterNode.id, { locked: false, animation: true });
          });
        }
      } catch (error) {
        setError(true);
      }
      setIsLoading(false);
    };

    processReport();
  }, [report, selectedScenario]);

  if (!report) {
    return <DidomiSkeleton isLoading={true} />;
  }

  return (
    <div>
      {report?.details_json?.vendors && (
        <DidomiCardContainer className="relative mt-l mb-l" style={{ '--card-inner-padding': '0px' }}>
          <div className="w-full h-[500px] bg-white mt-m mb-[50px]">
            <div id="graphId" className="" style={{ height: '100%', width: '100%' }}></div>
          </div>
          {
            <InitiatorDetailModal
              data-testid="initiator-detail-modal"
              isOpen={isOpen}
              report={report}
              vendorId={clickedVendorNodeId}
              onClose={handleClose}
              onVendorChange={handleVendorChange}
              nodes={nodeAndEdgeCache.current[selectedScenario]?.nodes}
            />
          }
          <div className="mt-x">
            <div className="absolute top-[5px] left-[5px] w-[410px]" style={{ borderRadius: '8px', padding: '15px', background: 'rgba(249,250,250,0.7)' }}>
              <p className="font-semibold mb-xxs" style={{ fontSize: '13px' }}>
                Concatenation Graph (unknown vendors included)
              </p>
              <table className="border-collapse border border-slate-400" style={{ border: '1px solid #ccc' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ccc', padding: '5px' }} colSpan={10}></th>
                    <th style={{ border: '1px solid #ccc', padding: '5px', width: '110px', fontSize: '11px' }}>Not dropping trackers</th>
                    <th style={{ border: '1px solid #ccc', padding: '5px', width: '110px', fontSize: '11px' }}>Dropping trackers</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by 3rd parties only
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(238, 128, 91)" strokeWidth="1" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(238, 128, 91)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by both the property and by 3rd parties
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(245, 192, 90)" strokeWidth="1.5" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(245, 192, 90)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', fontSize: '11px' }} colSpan={10}>
                      Initiated by the property only
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="21" height="21" className="m-auto">
                        <rect x="0" y="0" width="21" height="21" stroke="black" fill="rgb(33, 198, 178)" strokeWidth="1.5" />
                      </svg>
                    </td>
                    <td style={{ border: '1px solid #ccc', display: 'table-cell', verticalAlign: 'middle', padding: '5px', width: '110px' }}>
                      <svg width="25" height="21" className="m-auto" style={{ transform: 'scaleY(-1)' }}>
                        <polygon fill="rgb(33, 198, 178)" strokeWidth="1" stroke="black" points="0,0 25,0 12.5,21.65" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className="absolute top-[20px] right-[20px] w-[200px]">
                <DidomiSelect
                  label="Filter by scenario"
                  data-testid="scenario-filter"
                  value={selectedScenario}
                  disabled={availableScenarios.length === 0 || isLoading}
                  onValueChange={event => setSelectedScenario(event.detail as Scenario['key'])}
                >
                  <DidomiSelectOptions>
                    {availableScenarios.map(availableScenario => {
                      const scenario = SCENARIOS.find(({ key }) => key === availableScenario);
                      return <DidomiSelectOption key={'scenario' + scenario.key} value={scenario.key} label={scenario.text} />;
                    })}
                  </DidomiSelectOptions>
                </DidomiSelect>
              </div>
            </div>
            {(isLoading || error) && (
              <div>
                <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[200px]">
                  <p className="font-semibold mb-xxs" style={{ fontSize: '13px' }}>
                    {error ? 'Failed to process vendor graph' : 'Processing vendor graph...'}
                  </p>
                </div>
              </div>
            )}
          </div>
        </DidomiCardContainer>
      )}
    </div>
  );
};

export { ComplianceReportVendorGraph };
