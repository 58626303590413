import sha256 from 'crypto-js/sha256';
import { getInitiatorVendorIdentifier } from '@utils';

export enum TrackerParty {
  FIRST = '1st',
  THIRD = '3rd',
}

export type TrackerAttributes = {
  property_id: string;
  name: string;
  type: string;
  is_third_party: boolean;
  initiator_vendor_id?: number | string;
  initiator_url_host?: string;
  initiator_url_sld?: string;
};

/**
 * Generates the ID for a Tracker.
 *
 * The ID is composed by the following attributes joined by a pipe ('|'):
 * 1. property_id
 * 2. name
 * 3. party (either '1st' or '3rd' based on the is_third_party flag)
 * 4. initiator_vendor_id (determined by method: getInitiatorVendorIdentifier)
 * 5. type
 *
 * @param {PropertyTracker} propertyTracker - The PropertyTracker entity
 * @returns {string} The generated ID for the PropertyTracker
 */
export const generatePropertyTrackerId = (tracker: TrackerAttributes): string => {
  const party = tracker?.is_third_party ? TrackerParty.THIRD : TrackerParty.FIRST;
  const initiator_vendor_id = getInitiatorVendorIdentifier(tracker);

  const idComponents = [tracker.property_id, tracker.name, party, initiator_vendor_id, tracker.type].join('|');

  return sha256(idComponents).toString();
};
