import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { destructUrls } from '@utils';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useVendorSuggestions = ({ urls }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const fetchVendorsFromReportIds = async urls => {
    if (urls) {
      const aggregateDesctructUrls = destructUrls(urls);
      const response = await axiosWithInterceptors.get<any>(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/metadata-partners?organization_id=${organizationId}&urls=${aggregateDesctructUrls}`,
      );
      const data = response.data.data;
      // {'host_toto.com': [], 'host2_toto.com': [{...vendor}], }
      // => [{...vendor}]
      let result = Object.values(data).reduce((acc: any[], val: any[]) => {
        return acc.concat(val);
      }, []) as any[];

      result = result.reduce((unique: any[], item: any) => {
        return unique.findIndex(obj => obj.id === item.id) >= 0 ? unique : [...unique, item];
      }, []);

      response.data.data = result;
      return response.data.data;
    }
  };
  const results = useQuery<any, AxiosError>([`suggested-vendors`, urls], () => fetchVendorsFromReportIds(urls), {});

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
