// const timeFormat: Intl.DateTimeFormatOptions = { month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false, timeZoneName: 'short', timeZone: 'UTC' };

export const formatDate = (date: number | Date | null | string): string => {
  if (!date) return '-';
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(date));
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(date));
  let h = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).format(new Date(date));
  return `${mo} ${da} ${ye} at ${h}`.replace(' h', '');
};

export const formatDateMMDDYY = (date: number | Date | null | string): string => {
  if (!date) return '-';
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(date));
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(date));
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(date));
  return `${mo} ${da} ${ye}`;
};

export const sortArrayByDate = (list, dateKey) => {
  return list.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[dateKey]).valueOf() - new Date(a[dateKey]).valueOf();
  });
};
