import { SortConfig } from '@types';
import { getTranslatedValue } from '@didomi/helpers';

type PaginateAndSearchOptions<Z> = {
  search: string;
  searchFields: { field: string; transform?: (value: string) => string }[];
  translatableSearchFields: string[];
  limit: number;
  page: number;
  sortConfig: SortConfig<Z>;
  filters?: Array<any>;
};

const getTranslationValueFilter = <T>(items: T, field, translatableSearchFields: string[]): string => {
  const hasTranslation = translatableSearchFields.includes(field);
  return (hasTranslation ? getTranslatedValue(getObjectValue(items, field))?.toString() : getObjectValue(items, field)?.toString()) || '';
};

const filterItemsByText = <T>(items: T[], search: string, searchFields: { field: string; transform?: (value: string) => string }[], translatableSearchFields: string[]): T[] => {
  if (!search) return items;

  const searchText = search.toLowerCase();

  return items.filter(
    p =>
      translatableSearchFields.reduce(
        (result, field) =>
          result || (getTranslatedValue(getObjectValue(p, field)) ? getTranslatedValue(getObjectValue(p, field))?.toString().toLowerCase()?.includes(searchText) : false),
        false,
      ) ||
      searchFields.reduce(
        (result, searchField) =>
          result ||
          (getObjectValue(p, searchField.field, searchField.transform)
            ? getObjectValue(p, searchField.field, searchField.transform).toString().toLowerCase()?.includes(searchText)
            : false),
        false,
      ),
  );
};

export const paginateAndSearchItems = <T, Z>(
  items: T[],
  { search, searchFields = [], translatableSearchFields = [], limit, page, sortConfig, filters }: PaginateAndSearchOptions<Z>,
): { data: T[]; total: number; totalItems: T[] } => {
  if (!items?.length) return { data: [], total: 0, totalItems: [] };

  let filterItems = items;

  if (search?.length) {
    const searchText = search.toLowerCase();
    filterItems = filterItemsByText<T>(items, searchText, searchFields, translatableSearchFields);
  }

  if (filters?.length) {
    for (const filter of filters) {
      const filtered = filter.filter(filterItems, filter.value);
      filterItems = filtered;
    }
  }

  filterItems.sort((a, b) => {
    const sortResult = getTranslationValueFilter(a, sortConfig?.field, translatableSearchFields)?.localeCompare(
      getTranslationValueFilter(b, sortConfig?.field, translatableSearchFields),
    );
    return sortConfig?.dir === 'asc' ? sortResult : sortResult * -1;
  });

  const totalFilterPurposes = filterItems?.length;

  const paginatedFilteredPurposes = filterItems?.slice((page - 1) * limit, page * limit);

  return {
    data: paginatedFilteredPurposes,
    total: totalFilterPurposes,
    totalItems: filterItems,
  };
};

const getObjectValue = (object: any, key: string, transform?: (value: string) => string) => {
  let value;
  const keys = key.split('.');
  if (keys?.length > 1) {
    value = keys.reduce((o, d) => (o[d] ? o[d] : o), object);
  } else {
    value = object[key];
  }
  return transform ? transform(value) : value;
};
