import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, PropertyTrackerItem } from '@types';

type UsePatchPropertyTrackersArgs = {
  propertyId: string;
  trackers: Partial<PropertyTrackerItem>[];
};

/**
 * Send patch request to assign purposes or exemption category to selected trackers
 * */
export const usePatchPropertyTrackers = (options?: UseMutationOptions<AxiosResponse<PropertyTrackerItem[]>, AxiosError, UsePatchPropertyTrackersArgs>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const patchPropertyTrackers = async (data: UsePatchPropertyTrackersArgs) => {
    return await axiosWithInterceptors.patch<PropertyTrackerItem[]>(`${apiRoutes.crProperties}/${data.propertyId}/trackers`, data.trackers, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(patchPropertyTrackers, {
    ...options,
    onSuccess: (updatedPropertyTrackers: AxiosResponse<PropertyTrackerItem[]>, variables) => {
      const { propertyId } = variables;
      const propertyTrackersGet: PaginatedResponse<PropertyTrackerItem> = queryClient.getQueryData(['property-tracker-settings', propertyId]);

      // Update the property tracker settings data
      if (propertyTrackersGet) {
        const updatedItemsMap = new Map(propertyTrackersGet?.data.map(item => [item.id, item]));
        updatedPropertyTrackers?.data.forEach(item => {
          updatedItemsMap.set(item.id, item);
        });

        const updatedItems = Array.from(updatedItemsMap.values());

        queryClient.setQueryData(['property-tracker-settings', propertyId], {
          ...propertyTrackersGet,
          data: updatedItems,
          total: updatedItems.length,
        });
      }
    },
  });
};
