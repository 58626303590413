import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { useAuthMethods, useAuthToken } from '@didomi/utility-react';
import axios, { AxiosInstance } from 'axios';

const axiosWithLogoutInstance = axios.create();
let axiosWithLogoutInterceptors: AxiosInstance;

export const useAxiosWithLogOut = () => {
  const { logout } = useAuthMethods();
  const token = useAuthToken();
  axiosWithLogoutInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  if (axiosWithLogoutInterceptors) {
    return axiosWithLogoutInterceptors;
  }

  axiosWithLogoutInterceptors = addAxiosResponseInterceptors(axiosWithLogoutInstance, {
    onExpiredToken: logout,
    onForbidden: logout,
  });
  return axiosWithLogoutInterceptors;
};
