import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { TCFStatusType, TrackerLifetimeFilterKey, TrackerPartyFilterKey, TrackerType, VendorLegalBasisFilterKey, ExemptionStatusFilterKey } from '@enums';
import { AggregatedTracker, AggregatedVendor } from '@types';
import { TrackerLifetime, getTrackerTypeValue } from '@utils';
import { TrackerLifetimeFilter } from '../../interfaces';

// Tracker's lifetime conditions
const isLifetimeSessionOrUnknown = (tracker: Partial<AggregatedTracker>) => tracker.lifetime === TrackerLifetime.SESSION_OR_UNKNOWN;
const isLifetimeLowerThan6Months = (tracker: Partial<AggregatedTracker>) =>
  tracker.lifetime !== TrackerLifetime.SESSION_OR_UNKNOWN && tracker.lifetime_in_months_numeric >= 0 && tracker.lifetime_in_months_numeric <= 6;
const isLifetimeBetween6MonthsAnd12Months = (tracker: Partial<AggregatedTracker>) => tracker.lifetime_in_months_numeric > 6 && tracker.lifetime_in_months_numeric <= 12;
const isLifetimeBetween12And24Months = (tracker: Partial<AggregatedTracker>) => tracker.lifetime_in_months_numeric > 12 && tracker.lifetime_in_months_numeric <= 24;
const isLifetimeMoreThan24Months = (tracker: Partial<AggregatedTracker>) => tracker.lifetime_in_months_numeric > 24;

// Tracker's lifetime available filters
const trackerLifetimeFilters: TrackerLifetimeFilter[] = [
  { key: TrackerLifetimeFilterKey.SESSION, label: 'Session / Unknown', isValid: isLifetimeSessionOrUnknown },
  {
    key: TrackerLifetimeFilterKey.LESS_THAN_SIX_MONTHS,
    label: 'Less than 6 months',
    isValid: isLifetimeLowerThan6Months,
  },
  {
    key: TrackerLifetimeFilterKey.BETWEEN_SIX_AND_TWELVE_MONTHS,
    label: 'Between 6 and 12 months',
    isValid: isLifetimeBetween6MonthsAnd12Months,
  },
  {
    key: TrackerLifetimeFilterKey.BETWEEN_TWELVE_AND_TWENTY_FOUR_MONTHS,
    label: 'Between 12 and 24 months',
    isValid: isLifetimeBetween12And24Months,
  },
  {
    key: TrackerLifetimeFilterKey.MORE_THAN_TWENTY_FOUR_MONTHS,
    label: 'More than 24 months',
    isValid: isLifetimeMoreThan24Months,
  },
];

export const lifetimeFiltersLookup: Record<TrackerLifetimeFilterKey, TrackerLifetimeFilter> = trackerLifetimeFilters.reduce(
  (acc: Record<TrackerLifetimeFilterKey, TrackerLifetimeFilter>, filter: TrackerLifetimeFilter) => ({ ...acc, [filter.key]: filter }),
  {} as Record<TrackerLifetimeFilterKey, TrackerLifetimeFilter>,
);

export const trackerFilters = (trackers: AggregatedTracker[] = []) => {
  const functionalScenarios = Array.from(new Set(trackers.map(tracker => tracker.functional_scenario)));

  const filters: Record<string, any> = {
    type: {
      placeholder: 'Type',
      value: '',
      multi: true,
      options: Object.values(TrackerType).map(type => ({
        value: type,
        label: getTrackerTypeValue(type),
      })),
    },
    party: {
      placeholder: '1st/3rd party',
      value: '',
      multi: true,
      options: [
        { value: TrackerPartyFilterKey.FIRST_PARTY, label: 'First party' },
        { value: TrackerPartyFilterKey.THIRD_PARTY, label: 'Third party' },
      ],
    },
    lifetime: {
      placeholder: 'Lifetime',
      value: '',
      multi: true,
      allowAutomaticSize: true,
      options: trackerLifetimeFilters.map(({ key, label }) => ({ value: key, label })),
    },
  };

  if (functionalScenarios.length > 1) {
    filters.functionalScenario = {
      placeholder: 'User behaviour',
      value: '',
      multi: true,
      options: functionalScenarios.map(scenario => ({ value: scenario, label: TRANSLATED_SCENARIOS_MAP[scenario] })).sort((a, b) => a.label.localeCompare(b.label)),
    };
  }

  filters.isExempt = {
    placeholder: 'Exemption',
    value: '',
    multi: true,
    options: [
      { value: ExemptionStatusFilterKey.YES, label: 'Yes' },
      { value: ExemptionStatusFilterKey.NO, label: 'No' },
    ],
  };

  return filters;
};

export const vendorsFilters = (vendors: AggregatedVendor[] = []) => {
  const functionalScenarios = Array.from(new Set(vendors.map(vendor => vendor.functional_scenario)));
  const filters: Record<string, any> = {
    tcfStatus: {
      placeholder: 'TCF / Non-TCF',
      value: '',
      multi: true,
      options: [
        { value: TCFStatusType.ACTIVE, label: 'TCF' },
        { value: TCFStatusType.INACTIVE, label: 'Non-TCF' },
      ],
    },
    legalBasis: {
      placeholder: 'Legal Basis',
      value: '',
      multi: true,
      options: [
        { value: VendorLegalBasisFilterKey.CONSENT_ONLY, label: 'Consent only' },
        { value: VendorLegalBasisFilterKey.LEGITIMATE_INTEREST, label: 'Legitimate interest' },
        { value: VendorLegalBasisFilterKey.BOTH, label: 'Both' },
      ],
    },
  };
  if (functionalScenarios.length > 1) {
    filters.functionalScenario = {
      placeholder: 'User behaviour',
      value: '',
      multi: true,
      options: functionalScenarios.map(scenario => ({ value: scenario, label: TRANSLATED_SCENARIOS_MAP[scenario] })).sort((a, b) => a.label.localeCompare(b.label)),
    };
  }
  return filters;
};
