import React, { useState } from 'react';
import { DidomiSelectCustomEvent } from '@didomi/ui-atoms';
import { DidomiModal, DidomiButton, DidomiSelect, DidomiModalHeader, DidomiModalContent, DidomiSelectOptions, DidomiSelectOption } from '@didomi/ui-atoms-react';
import { ExemptionCategory, AggregatedTracker } from '@types';
import { getTranslatedValue } from '@didomi/helpers';

interface ExemptTrackerModalProps {
  isOpen: boolean;
  tracker?: AggregatedTracker;
  exemptionCategories: ExemptionCategory[];
  hideRemoveExemption?: boolean;
  onClose: (value: boolean) => void;
  onSubmit: (data: { selectedPurposeIds?: string[]; selectedExemptionCategoryId?: string }) => void;
}

export const ExemptTrackerModal = ({ isOpen, tracker, hideRemoveExemption = true, exemptionCategories, onClose, onSubmit }: ExemptTrackerModalProps) => {
  const [selectedExemptionCategoryId, setSelectedExemptionCategoryId] = useState(null);

  const handleExemptionCategorySelect = (e: DidomiSelectCustomEvent<string[]>) => {
    setSelectedExemptionCategoryId(e.detail);
  };

  const handleDialogChange = (e: CustomEvent) => {
    if (isOpen && !e.detail) {
      onClose(e.detail);
    }
  };

  const isSubmitDisabled = !selectedExemptionCategoryId;

  return (
    <div>
      <DidomiModal id="modal" variant="slim" isOpen={isOpen} onOpenChange={handleDialogChange} data-testid="ValidationExemptTrackerModal" closable>
        <DidomiModalHeader modal-sub-title="Tracker exemption" modal-description="You can modify the exemption category or remove the exemption status."></DidomiModalHeader>
        <DidomiModalContent>
          {exemptionCategories && (
            <>
              <DidomiSelect
                data-testid="tracker-exemption-category"
                data-skeleton
                label={'Exemption category'}
                placeholder={'Select from the list'}
                value={tracker?.exemption_category_id || selectedExemptionCategoryId}
                onValueChange={handleExemptionCategorySelect}
                hideErrorMessage={true}
                required
              >
                <DidomiSelectOptions>
                  {exemptionCategories.map((exemptionCategory: ExemptionCategory) => {
                    return <DidomiSelectOption key={exemptionCategory.id} value={exemptionCategory.id} label={getTranslatedValue(exemptionCategory.title)}></DidomiSelectOption>;
                  })}
                </DidomiSelectOptions>
              </DidomiSelect>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px' }}>
                <DidomiButton size="medium" variant="secondary" style={{ marginBottom: '10px' }} full-width onClick={() => onClose(false)}>
                  Cancel
                </DidomiButton>
                {!hideRemoveExemption && (
                  <DidomiButton
                    size="medium"
                    variant="secondary"
                    style={{ marginBottom: '10px' }}
                    full-width
                    onClick={() => {
                      setSelectedExemptionCategoryId(null);
                      onSubmit({ selectedExemptionCategoryId: null });
                    }}
                  >
                    Remove Exemption
                  </DidomiButton>
                )}
                <DidomiButton
                  size="medium"
                  variant="main"
                  full-width
                  disabled={isSubmitDisabled}
                  onClick={() => {
                    !isSubmitDisabled && onSubmit({ selectedExemptionCategoryId });
                    setSelectedExemptionCategoryId(null);
                  }}
                >
                  Save
                </DidomiButton>
              </div>
            </>
          )}
        </DidomiModalContent>
      </DidomiModal>
    </div>
  );
};
