import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { CreateMemberPropertyNotificationPayload, MemberPropertyNotification } from '@types';

export const useCreateMemberPropertyNotification = (
  options?: UseMutationOptions<AxiosResponse<MemberPropertyNotification>, AxiosError, CreateMemberPropertyNotificationPayload>,
) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const createMemberPropertyNotification = async (payload: CreateMemberPropertyNotificationPayload) => {
    return await axiosWithInterceptors.post<MemberPropertyNotification>(`${apiRoutes.memberPropertyNotifications}`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(createMemberPropertyNotification, {
    ...options,
    onSuccess: (createdEntity: AxiosResponse<MemberPropertyNotification>, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['member-property-notifications'] });

      if (options?.onSuccess) {
        options.onSuccess(createdEntity, variables, context);
      }
    },
  });
};
