import { ScenarioType, ScenarioActionType } from '@enums';
import { CustomScenario } from '@types';

export const nonAcmScenariosInitData: CustomScenario[] = [
  {
    enabled: true,
    type: ScenarioType.ACCEPT_ALL,
    scenario_actions: [
      {
        id: crypto.randomUUID(),
        type: ScenarioActionType.ACCEPT,
        order: 0,
      },
    ],
  },
];

export const acmScenariosInitData: CustomScenario[] = [
  {
    enabled: true,
    type: ScenarioType.ACCEPT_ALL,
    scenario_actions: [
      {
        id: crypto.randomUUID(),
        type: ScenarioActionType.ACCEPT,
        order: 0,
      },
    ],
  },
  {
    enabled: true,
    type: ScenarioType.REFUSE_ALL,
    scenario_actions: [
      {
        id: crypto.randomUUID(),
        type: ScenarioActionType.REFUSE,
        order: 0,
      },
    ],
  },
  {
    enabled: true,
    type: ScenarioType.NO_ACTIONS,
    scenario_actions: [],
  },
];
