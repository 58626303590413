import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { PaginatedResponse, RootProperty } from '@types';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

export const useProperty = ({ withReports = true }) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const fetchOrganizationProperties = async () => {
    try {
      const response = await axiosWithInterceptors.get<PaginatedResponse<RootProperty>>(
        `${CONFIG.environmentConfig.apiBaseUrl}agnostik/properties?organization_id=${organizationId}${withReports ? '&$with[]=latest_reports' : ''}&$limit=1000`,
      );

      const organizationProperties = await axiosWithInterceptors.get<PaginatedResponse<any>>(
        `${CONFIG.environmentConfig.apiBaseUrl}properties?organization_id=${organizationId}&$sort[created_at]=-1&$limit=1000`,
      );
      const didomiProperties = organizationProperties.data;
      let responseData = response.data;
      responseData.data = didomiProperties.data
        .map(property => {
          const agnostikProperty = responseData.data.find(agnoProperty => agnoProperty.id === property.id);
          if (agnostikProperty) {
            return {
              ...agnostikProperty,
              ...property,
              scenarios: agnostikProperty.collect_options?.custom_scenarios || [],
            };
          }
          property.reports = [];
          return property;
        })
        .filter(property => property?.id && !!property.reports);
      return responseData;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  const results = useQuery<PaginatedResponse<RootProperty>, AxiosError>(['properties', organizationId], fetchOrganizationProperties, {
    refetchInterval: false,
  });

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
