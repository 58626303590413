import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { apiRoutes } from '@constants';
import { useAxiosWithLogOut } from '@hooks';
import { PropertyTrackerItem } from '@types';

type UseClonePropertyTrackerSettingsArgs = {
  sourcePropertyId: string;
  targetPropertyIds: string[];
};

/**
 * Send requests to duplicate property trackers settings to targeted properties
 * */
export const useClonePropertyTrackerSettings = (options?: UseMutationOptions<AxiosResponse<PropertyTrackerItem[]>, AxiosError, UseClonePropertyTrackerSettingsArgs>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();

  const postToClonePropertyTrackerSettings = async (data: UseClonePropertyTrackerSettingsArgs) => {
    const payload = {
      property_ids: data.targetPropertyIds,
    };
    return await axiosWithInterceptors.post<PropertyTrackerItem[]>(`${apiRoutes.crProperties}/${data.sourcePropertyId}/trackers/duplicate`, payload, {
      params: { organization_id: organizationId },
    });
  };

  return useMutation(postToClonePropertyTrackerSettings, {
    ...options,
    onSuccess: (updatedPropertyTrackers: AxiosResponse<PropertyTrackerItem[]>, variables) => {
      const { targetPropertyIds } = variables;

      // Update the property tracker settings data in cache for each property
      if (targetPropertyIds.length > 0) {
        targetPropertyIds.forEach(propertyId => {
          const updatedItems = updatedPropertyTrackers?.data.filter(item => item?.property_id === propertyId);
          queryClient.setQueryData(['property-tracker-settings', propertyId], {
            data: updatedItems,
            total: updatedItems.length,
          });
        });
      }
    },
  });
};
