import { filterVendors } from '@utils';
export const defineVendorsInitiadedBySelectedVendor = ({ stateVendorId, report }) => {
  const initiatedVendors = report?.details_json.vendors.filter(vendor => {
    return vendor?.initiators?.includes(JSON.stringify(stateVendorId)) || vendor?.initiators?.includes(stateVendorId);
  });
  return initiatedVendors?.length
    ? initiatedVendors.sort((a, b) => {
        const acompared = a.id ? a.name : a.url_sld;
        const bcompared = b.id ? b.name : b.url_sld;
        return bcompared.localeCompare(acompared);
      })
    : [];
};

export const defineFromWhichVendorIsInitiated = ({ report, selectedVendor }) => {
  const vendors = report?.details_json?.vendors.filter(vendor => {
    return selectedVendor?.initiators?.includes(vendor.key) || selectedVendor?.initiators?.includes(JSON.stringify(vendor.key));
  });

  return vendors?.length
    ? vendors.sort((a, b) => {
        const acompared = a.id ? a.name : a.url_sld;
        const bcompared = b.id ? b.name : b.url_sld;
        return bcompared.localeCompare(acompared);
      })
    : [];
};

export const filterByNote = array => {
  // Create a set to store canonical_partner_ids found in notes
  let idsInNotes = new Set();
  // Regular expression to match canonical_partner_id in the note
  let regex = /\(([^)]+)\)/;
  // Iterate over the array and extract canonical_partner_ids from notes
  array.forEach(element => {
    let notes = element.notes || '';
    let match = notes.match(regex);
    if (match) {
      let id = match[1];
      idsInNotes.add(id);
    }
  });
  // Filter the array to remove elements whose id is in idsInNotes
  let filteredArray = array.filter(element => !idsInNotes.has(element.id));

  return filteredArray;
};

export const aggregatePartnersForMatching = (proposedVendors, suggestedVendors, organizationId) => {
  let vnd = proposedVendors;

  // Filtered array to remove ATP/GATP/IAB vendors
  const filteredArr = filterVendors(filterByNote(vnd), organizationId);

  // add suggestedVendors
  const suggestedVendorIds = new Set(suggestedVendors?.map(vendor => vendor.id));

  const filteredArrWithSuggestion = filteredArr.map(vendor => {
    vendor['suggested'] = suggestedVendorIds.has(vendor?.id);
    return vendor;
  });
  return filteredArrWithSuggestion;
};
