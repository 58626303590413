import { useState } from 'react';
import { UserOptions } from 'jspdf-autotable';
import { get } from 'lodash';
import { TRANSLATED_SCENARIOS_MAP } from '@constants';
import { Cookie, ExportType, Report, ReportInfo, Request } from '@types';
import {
  addExemptionStatusAndPurposesToCookies,
  assignCookieIds,
  exportCSV,
  exportExcel,
  exportJSON,
  exportPDF,
  formatDateMMDDYY,
  getTrackerAgeInMonthOrDays,
  legalBasisFrom,
  getRanScenarios,
} from '@utils';
import { getTranslatedValue } from '@didomi/helpers';
import { useCustomAndIABPurposes } from './useCustomAndIABPurposes.hook';
import { usePropertyReport } from './usePropertyReport.hook';
import { usePropertyTrackerSettings } from './usePropertyTrackerSettings.hook';
import { useTrackerExemptionCategories } from './useTrackerExemptionCategories.hook';

type UseReportExportArgs = {
  report?: Report;
};

type ExportReportArgs = {
  reportId?: string;
  info: ReportInfo;
  type: ExportType;
};

type FUNCTION_EXTRACTOR<T, S> = (item: T, base?: S) => string | string[];

type EXTRACTOR<T, S> = string | FUNCTION_EXTRACTOR<T, S>;

type AFTER_EXTRACT_FUNCTION = (rows: Record<string, string>[]) => Record<string, any>[];

const TRACKER_EXTRACTOR: Record<string, EXTRACTOR<Cookie, Report>> = {
  'Initiator Vendor': (cookie: Cookie) => cookie.initiator_vendor_name || cookie.initiator_url_host,
  'Initiator URL': 'initiator_url_host',
  Vendor: (cookie: Cookie) => cookie.vendor_name || cookie.domain || cookie.sld,
  'Page url': 'page_url',
  'Cookie domain': 'domain',
  'Cookie name': 'name',
  'Lifetime (in seconds)': 'max_lifetime_seconds',
  sample: (cookie: Cookie) => (cookie.sample ? cookie.sample.replace(/,/g, '_').replace(/"/g, '') : ''),
  Party: (cookie: Cookie) => (cookie.is_third_party ? '3rd party' : '1st party'),
  'User behaviour': (cookie: Cookie) => TRANSLATED_SCENARIOS_MAP[cookie.functional_scenario],
  'Ran Scenarios': getRanScenarios,
  Type: 'type',
};

const COOKIE_POLICY_EXTRACTOR: Record<string, EXTRACTOR<Cookie, Report>> = {
  'Vendor Name': (cookie: Cookie) => cookie.vendor_name || cookie.domain || cookie.sld,
  'Vendor tcf id': 'vendor_iabv2_id',
  'Tracker Name': 'name',
  'Tracker domain': 'domain',
  'Tracker exemption': (cookie: Cookie) => (cookie.is_exempt ? 'Exempted' : 'Not exempted'),
  'Tracker exemption title': (cookie: Cookie) => cookie.exemptions?.map(exemption => getTranslatedValue(exemption.title)).join('') || '',
  'Tracker exemption description': (cookie: Cookie) => cookie.exemptions?.map(exemption => getTranslatedValue(exemption.description)).join('') || '',
  'Life Time': (cookie: Cookie) => getTrackerAgeInMonthOrDays(cookie.max_lifetime_seconds),
  '1st Party/ 3rd Party': (cookie: Cookie) => (cookie.is_third_party ? '3rd party' : '1st party'),
  'Tracker Type': 'type',
  'Tracker Purposes Ids': (cookie: Cookie) => cookie.purposes?.map(purpose => purpose.id) || [],
  'Tracker Purposes Names': (cookie: Cookie) => cookie.purposes?.map(purpose => getTranslatedValue(purpose.description)) || [],
};

const VENDOR_EXTRACTOR: Record<string, EXTRACTOR<Request, Report>> = {
  Date: (request: Request) => formatDateMMDDYY(request.start),
  Vendor: (request: Request) => request.vendor_name || request.initiator_url_host,
  'Page url': 'page_url',
  'Request url': 'url',
  'Is tag': (request: Request) => (request.type.includes('javascript') ? 'Yes' : 'No'),
  'Tag type': 'type',
  'TCF id': 'vendor_iabv2_id',
  'Initiator url': 'initiator_url',
  'Initiator vendor': (request: Request) => request.initiator_vendor_name || request.initiator_url_sld,
  'Legal Basis': (request: Request) => legalBasisFrom(request.vendor_tcfv2_data),
  'Initiator Legal Basis': (request: Request) => legalBasisFrom(request.initiator_vendor_tcfv2_data),
  'User behaviour': (request: Request) => TRANSLATED_SCENARIOS_MAP[request.functional_scenario],
  'Ran Scenarios': getRanScenarios,
};

export const REPORT_PDF_CONFIG: Partial<UserOptions> = {
  margin: { horizontal: 5, top: 5 },
  alternateRowStyles: {
    cellWidth: 50,
  },
  columnStyles: {
    Date: { cellWidth: 30 },
    'Is tag': { cellWidth: 15, halign: 'center' },
    'Tag type': { cellWidth: 25 },
    'TCF id': { cellWidth: 15, halign: 'center' },
    'Legal Basis': { cellWidth: 30, halign: 'center' },
    'User behaviour': { cellWidth: 35 },
    'Cookie domain': { cellWidth: 35 },
    'Initiator URL': { cellWidth: 35 },
    'Ran scenarios': { cellWidth: 35 },
    Vendor: { cellWidth: 25 },
    'Lifetime (in seconds)': { halign: 'center', cellWidth: 40 },
    'Initiator Vendor': { cellWidth: 25 },
    'Cookie name': { cellWidth: 35 },
    Party: { cellWidth: 25, halign: 'center' },
    'Initiator Legal Basis': { cellWidth: 30, halign: 'center' },
    'Request url': { cellWidth: 35, halign: 'center' },
    'Initiator url': { cellWidth: 35, halign: 'center' },
    'Page url': { cellWidth: 30, halign: 'center' },
    sample: { cellWidth: 30, halign: 'center' },
  },
  rowPageBreak: 'avoid',
};

/**
 * Groups rows by the 'Vendor Name' column and exports the result as a JSON file.
 *
 * @param {Record<string, string | string>[]} rows - The rows of extracted data.
 * @param {string} fileName - The name of the file to generate.
 */
const groupCookiePolicy: AFTER_EXTRACT_FUNCTION = (rows: Record<string, string>[]) => {
  const groupByColumn = 'Vendor Name';
  const cookiePolicyContent = {};

  rows.forEach(item => {
    const vendorName = item[groupByColumn] as string;
    if (!cookiePolicyContent[vendorName]) {
      cookiePolicyContent[vendorName] = {
        [`${groupByColumn}`]: vendorName,
        Trackers: [],
      };
    }
    cookiePolicyContent[vendorName].Trackers.push(item);
  });

  return Object.values(cookiePolicyContent);
};

const EXTRACTORS = {
  [ReportInfo.TRACKER]: TRACKER_EXTRACTOR,
  [ReportInfo.VENDOR]: VENDOR_EXTRACTOR,
  [ReportInfo.COOKIE_POLICY]: COOKIE_POLICY_EXTRACTOR,
};

const AFTER_EXTRACT_ROWS = {
  [ReportInfo.COOKIE_POLICY]: groupCookiePolicy,
};

const FILE_NAMES = {
  [ReportInfo.TRACKER]: 'tracker',
  [ReportInfo.VENDOR]: 'vendor',
  [ReportInfo.COOKIE_POLICY]: 'qualified-cookie-policy',
};

/**
 * Maps and reduces items (Cookies or Requests) into a structured format for report generation, using provided extractors.
 * @param {Report} report - The report object containing detailed information.
 * @param {Cookie[] | Request[]} items - An array of Cookie or Request objects to process.
 * @param {typeof TRACKER_EXTRACTOR | typeof VENDOR_EXTRACTOR} extractor - The specific extractor to use for mapping the items.
 * @returns {Record<string, string>[]} An array of records, each representing a row of data for the report.
 */
const getRows = (report: Report, items: Cookie[] | Request[], extractor: typeof TRACKER_EXTRACTOR | typeof VENDOR_EXTRACTOR) => {
  return items.map(item => {
    return Object.keys(extractor).reduce((acc, extractorKey) => {
      const extractorOperator = extractor[extractorKey];
      if (typeof extractorOperator === 'string') {
        acc[extractorKey] = get(item, extractorOperator);
      } else {
        acc[extractorKey] = extractorOperator(item, report);
      }
      return acc;
    }, {});
  });
};

/**
 * Generates a file (PDF, Excel, or CSV) based on the provided rows and type.
 * @param {Record<string, string>[]} rows - The rows of data to include in the file.
 * @param {ExportType} type - The type of file to generate (PDF, Excel, or CSV).
 * @param {string} fileName - The name of the file to generate.
 * @returns {Promise<void>} A promise that resolves when the file is successfully generated.
 */
const generateFile = async (rows: Record<string, any>[], type: ExportType, fileName: string) => {
  if (type === ExportType.PDF) {
    return exportPDF(rows, fileName, { orientation: 'landscape', format: 'a3' }, REPORT_PDF_CONFIG);
  }
  if (type === ExportType.EXCEL) {
    return await exportExcel(rows, fileName);
  }

  if (type === ExportType.JSON) {
    return exportJSON(rows, fileName);
  }

  return exportCSV(rows, fileName);
};

/**
 * Retrieves extractors based on the report information
 *
 * @param {ReportInfo} info - Information about the report type
 */
const getExtractors = (info: ReportInfo) => {
  let extractors = EXTRACTORS[info];
  if (info === ReportInfo.TRACKER) {
    extractors = {
      ...extractors,
      Purposes: (cookie: Cookie) => (cookie.purposes.length > 0 ? cookie.purposes.map(purpose => getTranslatedValue(purpose.description)).join(', ') : 'None'),
      Exemption: (cookie: Cookie) => (cookie.is_exempt ? 'Yes' : 'No'),
    };
  }

  return extractors;
};

/**
 * Custom React hook for managing the export of reports.
 * @param {UseReportExportArgs} params - The arguments object, containing an optional report.
 * @returns {object} An object containing the current loading status, error (if any), report info, report type, and the exportReport function.
 */
export const useReportExport = ({ report }: UseReportExportArgs = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportInfo, setReportInfo] = useState<ReportInfo>(null);
  const [reportType, setReportType] = useState<ExportType>(null);
  const [error, setError] = useState<Error>(null);

  const { fetch } = usePropertyReport({ enabled: false });
  const { fetch: fetchExemptionCategories } = useTrackerExemptionCategories();
  const { fetch: fetchPropertyTrackerSettings } = usePropertyTrackerSettings({ enabled: false });

  const { data: customAndIABPurposes } = useCustomAndIABPurposes();

  const getUpdatedCookies = async (info: ReportInfo, cookies: Cookie[], propertyId: string) => {
    let updatedCookies = assignCookieIds(propertyId, cookies);

    const exemptionCategories = await fetchExemptionCategories();
    const propertyTrackerSettings = await fetchPropertyTrackerSettings(propertyId);

    const cookiesWithExemptionAndPurposes = addExemptionStatusAndPurposesToCookies(
      updatedCookies,
      customAndIABPurposes,
      propertyTrackerSettings?.data,
      exemptionCategories?.data,
      true,
    );

    return info === ReportInfo.COOKIE_POLICY
      ? cookiesWithExemptionAndPurposes.filter(cookie => cookie.is_exempt || cookie.purpose_ids?.length > 0)
      : cookiesWithExemptionAndPurposes;
  };

  const exportReport = async ({ reportId, info, type }: ExportReportArgs) => {
    setIsLoading(true);
    setError(null);
    setReportInfo(info);
    setReportType(type);
    try {
      let reportToExport = report;
      if (!report || !report.details_json) {
        reportToExport = await fetch(reportId);
      }

      const {
        details_json: {
          property: { id: propertyId, cookies },
          requests,
        },
      } = reportToExport;

      const extractors = getExtractors(info);
      const items = info === ReportInfo.VENDOR ? requests : await getUpdatedCookies(info, cookies, propertyId);
      let rows = getRows(reportToExport, items, extractors);
      if (AFTER_EXTRACT_ROWS[info]) {
        rows = AFTER_EXTRACT_ROWS[info](rows);
      }

      const fileName = `export-${FILE_NAMES[info]}-${reportToExport.details_json.property.name.replace(/ /g, '-')}-${formatDateMMDDYY(reportToExport.created)}`.replace('_', '-');

      await generateFile(rows, type, fileName);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    reportInfo,
    reportType,
    exportReport,
  };
};
