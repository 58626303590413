import { AggregatedVendor, LegalBasis, Report, Vendor } from '@types';
import { getVendorIdentifier, getVendorLabel } from './nodesAndEdgesFromRequestsAndTrackers';

/**
 * Compute the legal basis of a vendor based
 * on its IAB TCFv2 purposes and legitimate purposes.
 *
 * Notes:
 * - Consent only legal basis: a vendor disclosing purposes only
 * no legitimate interest purposes
 * - Legitimate interest legal basis: a vendor disclosing legitimate
 * interest purposes only, no purposes
 * - Legitimate interest, Consent only: a vendor disclosing
 * both purposes and legitimate interest purposes
 *
 * @param tcfData
 */
export const legalBasisFrom = tcfData => {
  if (!tcfData?.purposes?.length && !tcfData?.legIntPurposes?.length) {
    return LegalBasis.UNKNOWN;
  }

  const purposes = tcfData?.purposes || [];
  const legitimateInterestPurposes = tcfData?.legIntPurposes || [];

  if (purposes.length && !legitimateInterestPurposes.length) {
    return LegalBasis.CONSENT_ONLY;
  }

  if (!purposes.length && legitimateInterestPurposes.length) {
    return LegalBasis.LEGITIMATE_INTEREST;
  }

  return LegalBasis.LEGITIMATE_INTEREST_CONSENT_ONLY;
};

export const defineVendorsTableData = (report: Report): AggregatedVendor[] => {
  if (!report) return [];
  const list = [];
  report.details_json.vendors.forEach((vendor: Vendor) => {
    vendor.vendor_id = vendor.id;
    vendor.vendor_name = vendor.name;
    const draftVendor = {
      isUnknow: !vendor.id,
      id: vendor.id,
      label: getVendorLabel(vendor),
      identifier: getVendorIdentifier(vendor),
      tcfIdNumber: vendor.vendor_iabv2_id || 0,
      tcfId: vendor.vendor_iabv2_id || '-',
      requests: vendor.ntags ? parseInt(vendor.ntags, 10) : 0,
      tcfv2_data_json: vendor.vendor_tcfv2_data || null,
      legalBasis: legalBasisFrom(vendor.vendor_tcfv2_data),
      functional_scenario: vendor?.functional_scenario,
      ran_scenarios: vendor?.ran_scenarios,
      url_sld: vendor?.url_sld,
      url_host: vendor?.url_host,
    };
    list.push(draftVendor);
  });
  return list as AggregatedVendor[];
};

/**
 * Processes a list of vendor data, filtered them based on unknown status and then categorizing them according
 * to its a domain and subdomain. Then only unique domains are retained by mapping them to their corresponding
 * vendors and discard subdomains. The result is a list of unique vendor objects with updated labels which represents the domain only.
 *
 * @param {AggregatedVendor[]} vendors - Array of vendor objects.
 * @returns {Object[]} - Filtered array of vendor objects.
 */
export const defineUnkowVendors = (vendors: AggregatedVendor[]) => {
  const list = new Map();
  vendors
    .filter(vendor => vendor.isUnknow)
    .forEach(vendor => {
      const domain = vendor.url_sld;
      if (domain) {
        let vendorDetails = list.get(domain);
        if (!vendorDetails) {
          vendorDetails = {
            ...vendor,
            merged_identifiers: [vendor.identifier],
          };
        } else {
          vendorDetails = {
            ...vendorDetails,
            merged_identifiers: [...vendorDetails.merged_identifiers, vendor.identifier],
          };
        }

        list.set(domain, vendorDetails);
      }
    });

  return Array.from(list, ([domain, value]) => ({ ...value, label: domain }));
};
