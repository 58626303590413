import { Property } from 'types/responses/property';

const HOUR = 1000 * 60 * 60;

export const didLastReportRunInPastHour = (property: Pick<Property, 'latest_reports'>) => {
  if (!property?.latest_reports?.length) return false;

  const lastRequestedCollectDateTime = new Date(property.latest_reports[0].created_at);
  const nextAllowedCollectRequestDateTime = lastRequestedCollectDateTime.getTime() + HOUR;
  return Date.now() < nextAllowedCollectRequestDateTime;
};
