import { ReportStatus } from '@enums';
import { AggregatedTracker, Property, AggregatedVendor, Report, Purpose, PropertyTrackerItem } from '@types';
import { defineVendorsTableData, defineUnkowVendors, defineTrackersTableData, assignCookieIds, addExemptionStatusAndPurposesToCookies } from '@utils';
interface Error {
  name: string;
  type: 'info' | 'tracker-table' | 'vendor-table';
  warningLevel: 'warning' | 'alert';
  dataTable?: AggregatedVendor[] | AggregatedTracker[] | any[];
  expandId?: string;
  entities: string;
  extraLink: string | null;
  extralinkText: string | null;
}

const trackersDroppedWithoutConsent = (trackers: AggregatedTracker[]) => {
  return trackers.filter(tracker => tracker?.functional_scenario === 'NO_USER_CHOICE');
};

const trackersDroppedDespiseRefuseAll = (trackers: AggregatedTracker[]) => {
  return trackers.filter(tracker => tracker?.functional_scenario === 'REFUSE_TO_ALL');
};

export const aggregateComplianceIssues = (report: Report, purposes: Purpose[], propertyTrackerSettings: PropertyTrackerItem[]): Error[] => {
  if (!report || report?.status !== ReportStatus.ANALYZED) return [];
  const issues = [];
  const property = report?.details_json?.property as Property;
  const reportCookiesWithIds = assignCookieIds(report?.details_json.property.id, report?.details_json.property.cookies || []);
  const updatedCookies = addExemptionStatusAndPurposesToCookies(reportCookiesWithIds, purposes, propertyTrackerSettings, [], true);

  const trackers = defineTrackersTableData(updatedCookies);
  const trackersWith13MonthsLifetime = trackers.filter(cookie => cookie.lifetime_in_months_numeric > 13);
  const vendorTableData = defineVendorsTableData(report);
  const unknowVendors = defineUnkowVendors(vendorTableData);
  const trackersDespiseRefuseAll = trackersDroppedDespiseRefuseAll(trackers);
  const trackersWithoutChoice = trackersDroppedWithoutConsent(trackers);

  if (trackersDespiseRefuseAll.length) {
    const error = {
      name: 'dropped despite user refusing consent.',
      entities: `${trackersDespiseRefuseAll.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      expandId: 'trackersDespiseRefuseAll',
      dataTable: trackersDespiseRefuseAll,
      extraLink: 'https://support.didomi.io/multi-scenario-scan',
      extralinkText: 'For more information.',
    } as Error;
    issues.push(error);
  }
  if (trackersWithoutChoice.length) {
    const error = {
      name: 'dropped despite user not making a choice.',
      entities: `${trackersWithoutChoice.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      expandId: 'trackersWithoutChoice',
      dataTable: trackersWithoutChoice,
      extraLink: 'https://support.didomi.io/multi-scenario-scan',
      extralinkText: 'For more information.',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.present_on_all_pages) {
    const error = {
      name: 'The Privacy Policy is not accessible from every page of the website.',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.optout) {
    const error = {
      name: 'The privacy policy does not seem to provide a way to opt out',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.user_consent.choice) {
    const error = {
      name: 'No CMP was detected on the website.',
      type: 'info',
      warningLevel: 'alert',
    } as Error;
    issues.push(error);
  }

  if (!property.privacy.user_consent.information) {
    const error = {
      name: 'The consent notice doesn’t provide information about purposes and vendors.',
      type: 'info',
      warningLevel: 'alert',
    } as Error;
    issues.push(error);
  }
  if (trackersWith13MonthsLifetime.length) {
    const error = {
      name: 'have lifetime longer than 13 months which is not recommended by GDPR.',
      entities: `${trackersWith13MonthsLifetime.length} trackers`,
      type: 'tracker-table',
      warningLevel: 'warning',
      dataTable: trackersWith13MonthsLifetime,
      expandId: 'lifetimeTrackers',
    } as Error;
    issues.push(error);
  }

  if (unknowVendors.length) {
    const error = {
      name: 'are unknown to our database, review them to be sure they are legit',
      entities: `${unknowVendors.length} vendors or initiators`,
      type: 'vendor-table',
      warningLevel: 'warning',
      dataTable: unknowVendors,
      expandId: 'unknowVendors',
    } as Error;
    issues.push(error);
  }
  if (!property.privacy.privacy_policy.present) {
    const error = {
      name: 'A Cookie Policy wasn’t found',
      type: 'info',
      warningLevel: 'warning',
    } as Error;
    issues.push(error);
  }

  return issues;
};
