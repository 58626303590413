import { MutableRefObject, useCallback } from 'react';
import { FormikProps } from 'formik';
import { ScenarioActionType, ScenarioType } from '@enums';
import { Property } from '../types/responses/property';

export const useScenarioChanges = (ref: MutableRefObject<FormikProps<Partial<Property>>>) => {
  const handleEnabledChange = useCallback(
    (value: boolean, index: number) => {
      ref.current.setFieldValue(`scenarios[${index}].enabled`, value);
    },
    [ref],
  );

  const handleAddScenario = useCallback(() => {
    ref.current.setFieldValue('scenarios', [
      ...ref.current.values.scenarios,
      {
        name: 'New scenario',
        type: ScenarioType.CUSTOM,
        enabled: false,
        scenario_actions: [],
      },
    ]);
  }, [ref]);

  const handleDeleteScenario = useCallback(
    (index: number) => {
      ref.current.setFieldValue(
        'scenarios',
        ref?.current?.values.scenarios.filter((_, i) => i !== index),
      );
    },
    [ref],
  );

  const handleNameChange = useCallback(
    (value: string, index: number) => {
      ref.current.setFieldValue(`scenarios[${index}].name`, value);
    },
    [ref],
  );

  const handleAddAction = useCallback(
    (index: number, action: ScenarioActionType) => {
      ref.current.setFieldValue(`scenarios[${index}].scenario_actions`, [
        ...ref.current.values.scenarios[index].scenario_actions,
        {
          // it will be replaced with a real id, currently is only used for unique key in the list
          // the main purpose is to enforce rerender when an action is deleted
          id: crypto.randomUUID(),
          type: action,
          order: ref.current.values.scenarios[index].scenario_actions.length,
        },
      ]);
    },
    [ref],
  );

  const handleDeleteAction = useCallback(
    (scenarioIndex: number, actionIndex: number) => {
      // filters out the action that is being deleted
      const filteredActions = ref.current.values.scenarios[scenarioIndex].scenario_actions.filter((_, i) => i !== actionIndex);

      // updates the actions order after deleting a action so that the order doesn't have any gaps
      const reorderedActions = filteredActions.map((action, i) => ({ ...action, order: i }));

      ref.current.setFieldValue(`scenarios[${scenarioIndex}].scenario_actions`, reorderedActions);
    },
    [ref],
  );

  const handleMoveAction = useCallback(
    (scenarioIndex: number, fromActionIndex: number, toActionIndex: number) => {
      const actions = ref.current.values.scenarios[scenarioIndex].scenario_actions;
      const newActions = [...actions];
      const [removed] = newActions.splice(fromActionIndex, 1);
      newActions.splice(toActionIndex, 0, removed);

      ref.current.setFieldValue(`scenarios[${scenarioIndex}].scenario_actions`, newActions);
    },
    [ref],
  );

  const handleCssSelectorChange = useCallback(
    (scenarioIndex: number, actionIndex: number, value: string) => {
      ref.current.setFieldValue(`scenarios[${scenarioIndex}].scenario_actions[${actionIndex}].css_selector`, value);
    },
    [ref],
  );

  const handleInputValueChange = useCallback(
    (scenarioIndex: number, actionIndex: number, value: string) => {
      ref.current.setFieldValue(`scenarios[${scenarioIndex}].scenario_actions[${actionIndex}].input_value`, value);
    },
    [ref],
  );

  return {
    handleEnabledChange,
    handleAddScenario,
    handleDeleteScenario,
    handleNameChange,
    handleAddAction,
    handleDeleteAction,
    handleMoveAction,
    handleCssSelectorChange,
    handleInputValueChange,
  };
};
