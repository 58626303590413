import React, { useCallback, useMemo, useState } from 'react';
import { filterSortPaginate } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent, DidomiFilterSelectOption } from '@didomi/ui-atoms';
import {
  DidomiFiltersBar,
  DidomiIconButton,
  DidomiMenu,
  DidomiMenuItem,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableTh,
} from '@didomi/ui-atoms-react';
import { ManageTrackerPolicyTableRow } from '@components';
import { ExemptionStatusFilterKey, TrackerPartyFilterKey, TrackerType } from '@enums';
import { useTableState } from '@hooks';
import { AggregatedTracker, ScenarioFilterKey, SortConfig } from '@types';
import { filterByFunctionalScenario, filterTrackersByParty, filterTrackersByType, filterByExemptionStatus } from '@utils';
import { ManageTrackerPolicyTableEmptyRow } from './ManageTrackerPolicyTableEmptyRow';
import { trackerFilters } from '../ComplianceReportDetail/filters';

interface ManageTrackerPolicyTableProps {
  trackers: AggregatedTracker[];
  selectedIds: string[];
  onSelectionChange: (selectedIds: string[]) => void;
  setOpenCloneTrackerSettingsDialog: (value: boolean) => void;
}

type SortableFields = 'name' | 'domain' | 'first_party' | 'initiator.label' | 'vendor.label' | 'type' | 'functional_scenario' | 'is_exempt';

type Filter = {
  type?: TrackerType[];
  party?: TrackerPartyFilterKey[];
  functionalScenario?: ScenarioFilterKey[];
  isExempt?: ExemptionStatusFilterKey[];
};

type FilterChangeType = { key: string; newValue: string | DidomiFilterSelectOption | DidomiFilterSelectOption[] };

type FilterMatch = (tracker: AggregatedTracker, search?: Filter[keyof Filter]) => boolean;

const SEARCH_FIELDS = [{ field: 'name' }];

const FILTER_MAP: Record<keyof Filter, FilterMatch> = {
  type: filterTrackersByType,
  party: filterTrackersByParty,
  functionalScenario: filterByFunctionalScenario,
  isExempt: filterByExemptionStatus,
};

const INITIAL_SORT: SortConfig<SortableFields> = { field: 'domain', dir: 'asc' };

export const ManageTrackerPolicyTable = ({ trackers, selectedIds, onSelectionChange, setOpenCloneTrackerSettingsDialog }: ManageTrackerPolicyTableProps) => {
  const [filter, setFilter] = useState<Filter>({});
  const [sortConfig, setSortConfig] = useState<SortConfig<SortableFields>>(INITIAL_SORT);

  const filterConfig = useMemo(() => {
    const { lifetime, ...remainingFilters } = trackerFilters(trackers);
    return remainingFilters;
  }, [trackers]);

  const {
    limit,
    page: currPage,
    search,
    changeLimit: setLimit,
    changeSearch: setSearch,
    changePage: setCurrPage,
  } = useTableState({
    defaultLimit: 10,
    defaultFilters: {},
  });

  const executeTrackerFilter = useCallback(
    (tracker: AggregatedTracker): boolean => {
      return Object.entries(filter).every(([filterKey, filterValue]) => FILTER_MAP[filterKey](tracker, filterValue));
    },
    [filter],
  );

  const { data: trackersToDisplay, total: paginationTotalTrackers } = useMemo(() => {
    return filterSortPaginate(trackers, {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      filters: [executeTrackerFilter],
      sortConfig,
    });
  }, [trackers, search, limit, currPage, executeTrackerFilter, sortConfig]);

  const handleSearchChange = (e: CustomEvent<string>) => setSearch(e.detail);

  const setFirstPage = () => {
    setCurrPage(
      new CustomEvent<{ page: number }>('pageChange', {
        detail: {
          page: 1,
        },
      }),
    );
  };

  const handleFilterChange = (newFilterChange: DidomiFiltersBarCustomEvent<FilterChangeType>) => {
    const key = newFilterChange.detail.key;
    const value = newFilterChange.detail.newValue;
    setFilter(currentFilter => ({ ...currentFilter, [key]: value }));
    setFirstPage();
  };

  const handleFiltersReset = () => {
    setSearch('');
    setFilter({});
    setSortConfig(INITIAL_SORT);
    setFirstPage();
  };

  const handleSortChange = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setFirstPage();
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const displayPaginator = trackers.length > 0;

  return (
    <div>
      <DidomiSkeleton variant="layout">
        <div style={{ width: 'calc(100% - 4px)', margin: 'auto' }}>
          <div style={{ width: '3%', display: 'inline-block', textAlign: 'left' }}>
            <DidomiIconButton id="menu-button-variant" variant="rounded" icon="submenu"></DidomiIconButton>
            <DidomiMenu for="menu-button-variant" variant="main">
              <DidomiMenuItem onClick={() => setOpenCloneTrackerSettingsDialog(true)} data-cy="clone-tracker-settings">
                {'Apply to other properties'}
              </DidomiMenuItem>
            </DidomiMenu>
          </div>
          <div style={{ width: '97%', display: 'inline-block' }}>
            <DidomiFiltersBar
              data-testid="filter-bar-tracker"
              data-skeleton
              filters={filterConfig}
              id="didomi-filters-without-default-value-tracker"
              defaultValue=""
              leftText={`${paginationTotalTrackers} trackers`}
              onSearchTextChange={handleSearchChange}
              showSearch
              onFilterValueChange={handleFilterChange}
              placeholderTextFilter={'Search by name'}
              onClearAllFilters={handleFiltersReset}
              numberOfVisibleFilters={5}
            ></DidomiFiltersBar>
          </div>
        </div>
        <div>
          <DidomiTable
            sortable
            sortBy={sortConfig.field}
            sortDirection={sortConfig.dir}
            onSortChange={handleSortChange}
            style={{ width: 'calc(100% - 2px)', margin: '16px auto' }}
            data-skeleton
            selectable={true}
            selectedItems={selectedIds}
            selectOneTooltip="Select to add purposes or exemption"
            onToggleAllRowsSelectedChange={e => onSelectionChange(e.detail.newSelectedItems)}
            onRowSelectionChange={e => onSelectionChange(e.detail.newSelectedItems)}
          >
            <DidomiTableHeading>
              <DidomiTableHeaderRow>
                <DidomiTableTh sortId="name" data-testid="sortByName">
                  Tracker name
                </DidomiTableTh>
                <DidomiTableTh sortId="first_party" data-testid="sortByFirstParty">
                  1ST/3D party
                </DidomiTableTh>
                <DidomiTableTh sortId="initiator.label" data-testid="sortByInitiator">
                  Initiator
                </DidomiTableTh>
                <DidomiTableTh sortId="vendor.label" data-testid="sortByVendor">
                  Vendor
                </DidomiTableTh>
                <DidomiTableTh sortId="type" data-testid="sortByType">
                  Type
                </DidomiTableTh>
                <DidomiTableTh sortId="functional_scenario" data-testid="sortByFunctionalScenario">
                  User behaviour
                </DidomiTableTh>
                <DidomiTableTh>Purposes</DidomiTableTh>
                <DidomiTableTh sortId="is_exempt" data-testid="sortByExemptionStatus">
                  Exemption
                </DidomiTableTh>
              </DidomiTableHeaderRow>
            </DidomiTableHeading>
            <DidomiTableBody data-skeleton>
              {trackersToDisplay.length > 0 && <ManageTrackerPolicyTableRow trackers={trackersToDisplay} />}
              {trackersToDisplay.length === 0 && <ManageTrackerPolicyTableEmptyRow />}
            </DidomiTableBody>
          </DidomiTable>

          {displayPaginator && (
            <DidomiPaginator
              data-skeleton
              className="self-end mt-xs w-full justify-end flex"
              variant="normal"
              page={currPage}
              itemCount={paginationTotalTrackers}
              size={limit}
              onPageSizeChange={setLimit}
              onPageChange={setCurrPage}
            />
          )}
        </div>
      </DidomiSkeleton>
    </div>
  );
};
