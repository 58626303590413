import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { apiRoutes } from '@constants';
import { PaginatedResponse, Report, SortConfig } from '@types';
import { useAxiosWithLogOut } from '../../useAxiosWithInterceptors.hook';

type UseReportsOptions = UseQueryOptions<PaginatedResponse<Report>, AxiosError> & {
  id?: string[];
  propertyId?: string;
  limit?: number;
  sort?: SortConfig<keyof Report>;
};

export const useReports = (options: UseReportsOptions = {}) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetch = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Report>>(apiRoutes.reports, {
      params: {
        organization_id: organizationId,
        ...(options.id && { id: options.id }),
        ...(options.propertyId && { property_id: options.propertyId }),
        ...(options.limit && { $limit: options.limit }),
        ...(options.sort && { [`$sort[${options.sort.field}]`]: options.sort.dir === 'asc' ? 1 : -1 }),
      },
    });

    return response.data;
  };

  return useQuery<PaginatedResponse<Report>, AxiosError>(['reports', organizationId, ...(options.id || []), options.propertyId], fetch, options);
};
