import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@constants';

export const useAccessPolicies = () => {
  const { hasAccess: hasComplianceReportViewAccess, isLoading: isLoadingComplianceReportViewAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.COMPLIANCE_REPORTS_VIEWER);
  const { hasAccess: hasComplianceReportEditAccess, isLoading: isLoadingComplianceReportEditAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.COMPLIANCE_REPORTS_EDITOR);
  const { hasAccess: hasAdminAccess, isLoading: isLoadingAdminAccess } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.ADMIN);

  return {
    hasComplianceReportViewAccess,
    hasComplianceReportEditAccess,
    hasAdminAccess,
    isLoading: isLoadingComplianceReportViewAccess || isLoadingComplianceReportEditAccess || isLoadingAdminAccess,
  };
};
